import { useQuery, UseQueryResult } from "react-query";
import { IMonTable } from "../../../../components/Table/Column";
import { filter } from "../../../../store/SideFilters/RequestFilters/types";
import { IStateFilters } from "../../../../store/SideFilters/types";
import api from "../../../api";

import { ITypeGraphicHorizontalBar } from "../../OnePage/onePageTypes";

export enum ELavarage {
  PRESENCA = "PRESENCA",
  GONDOLA = "GONDOLA",
  PONTO_EXTRA = "PONTO_EXTRA",
}
export interface ISelectedFiltersKpi {
  selectPeriod: filter;
  selectedPresence: filter;
  selectedCategoryFilter: string;
  selectedBrandFilter: string;
}

export interface IColumnChartFormatData {
  data: ITypeGraphicHorizontalBar;
  header: string;
  label: string;
  total: string;
}

export interface IKpi {
  periodFilter: string;
  total: number;
  data: {
    dataTable: IMonTable[];
    activeStore: any;
    act: IColumnChartFormatData;
    lm: IColumnChartFormatData;
    l3m: IColumnChartFormatData;
    ytd: IColumnChartFormatData;
    lastWeek: IColumnChartFormatData;
    penultimateWeek: IColumnChartFormatData;
  };
}

const requestKpiStore = async (filters: IStateFilters): Promise<IKpi> => {
  let categoryFilter;
  let brandFilter;
  let filterCategorySideBar = {
    businessUnitId: filters.promoFilter.businessUnitId.selectedFilterId,
    category: filters.promoFilter.category.selectedFilterId,
    brand: filters.promoFilter.brand.selectedFilterId,
    segments: filters.promoFilter.segments.selectedFilterId,
  };

  if (filters.internalKpiStore.selectedPresence.id !== ELavarage.PRESENCA) {
    filterCategorySideBar = {
      businessUnitId: "",
      category: filters.internalKpiStore.selectedCategoryFilter,
      brand: filters.internalKpiStore.selectedBrandFilter,
      segments: "",
    };
  }

  const result = await api.get<any>(`kpi_store_presence/`, {
    params: {
      leverage: filters.internalKpiStore.selectedPresence.id || null,
      period: filters.internalKpiStore.selectPeriod.id || null,
      businessUnit: filterCategorySideBar.businessUnitId || null,
      firstItem: filters.internalKpiStore.firstItem,
      lastItem: filters.internalKpiStore.lastItem,
      category: filterCategorySideBar.category || null,
      subCategory: filterCategorySideBar.brand || null,
      segment: filterCategorySideBar.segments || null,
      subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
      banner: filters.promoFilter.banner.selectedFilterId || null,
      region: filters.promoFilter.region.selectedFilterId || null,
      uf: filters.promoFilter.uf.selectedFilterId || null,
      director: filters.promoFilter.commercialDirector.selectedFilterId || null,
      manager: filters.promoFilter.commercialManager.selectedFilterId || null,
      customer_sales_rep:
        filters.promoFilter.commercialSaler.selectedFilterId || null,
      cnpj: filters.promoFilter.storeCNPJ || null,
      size: filters.internalKpiStore.limitItem,
      gpdv: filters.promoFilter.gpdv.selectedFilterId || null,
      retailEnviroment:
        filters.promoFilter.retailEnviroment.selectedFilterId || null,
    },
  });

  return result.data;
};

export const useRequestKpiStore = (
  filters: IStateFilters
): UseQueryResult<IKpi, unknown> => {
  return useQuery(
    ["presenceKpiStore", filters],
    () => requestKpiStore(filters),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: 2,
    }
  );
};
