import { Flex, Image, Text } from "@chakra-ui/react";
import react from "react";

// types
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { INavContent } from "../../store/Navigation/types";

// images
import lupa from "../../assets/OnePage/magnifying_glass_black.png";

// actions
import * as ANavigator from "../../store/Navigation/actions";

interface IOwnProps {
  label: string;
  icon?: string;
  goPage?: INavContent;
  mw?: string;
  padding?: string;
  onAction?(): void;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
  };
}

type props = IDispatchProps & IOwnProps;

const SeeDetailButton: react.FC<props> = ({
  label,
  goPage,
  icon,
  DNavigator,
  mw,
  padding,
  onAction,
}: props) => {
  return (
    <Flex color="black" justify="flex-end" align="center" p={padding}>
      {icon && <Image src={icon} width="50px" alt="Icon store" />}
      <Flex position="relative" cursor="pointer">
        <Text
          fontStyle="italic"
          bg="#e2e2e2"
          pl="12px"
          minW={mw}
          pr="50px"
          h="30px"
          py="5px"
          whiteSpace="nowrap"
          onClick={() =>
            goPage ? DNavigator.changeDash(goPage) : onAction && onAction()
          }
        >
          {label}
        </Text>
        <Image
          src={lupa}
          alt="icone lupa"
          position="absolute"
          right="0"
          width="45px"
          mt="-5px"
        />
      </Flex>
    </Flex>
  );
};

SeeDetailButton.defaultProps = {
  icon: "",
  mw: "",
  padding: "5px 0px",
  goPage: undefined,
  onAction: undefined,
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(SeeDetailButton);
