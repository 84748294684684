import styled from "styled-components";
import { theme } from "../../styles/theme";

interface styledProps {
  show: boolean;
}

export const SideBarPrifile = styled.section<styledProps>`
  position: absolute;
  right: 0;
  height: 100%;
  width: 375px;
  transform: ${(props) =>
    props.show ? `translate('100%')` : "translateX(110%)"};
  background-color: white;
  box-shadow: -1px 0px 16px -4px black;
  z-index: 999999;
  transition: 0.4s;
  padding: 20px;
`;

export const BackgroundProfile = styled.div`
  position: absolute;
  z-index: 2;
  width: calc(100% - 234px);
  margin: 0;
  height: 100%;
  padding: 0;
`;

export const Header = styled.div`
  width: 100%;
  height: 52px;
  font-size: 0.6em;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 10px 0 0;
  position: relative;
  color: #b5b5c3;

  svg {
    margin-top: 5px;
    cursor: pointer;
  }

  & > h2 {
    font-size: 3em;
    margin: 0;
    color: #3f4254;
  }

  & > span {
    width: auto;
    position: absolute;
    text-align: right;
    right: 0;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s;
    padding: 0 5px;
  }

  & > span:hover {
    background-color: #ff0000;
    color: white;
    border-radius: 3px;
  }
`;

export const DataProfile = styled.div`
  display: grid;
  margin: 30px 0px;
  height: 120px;
  grid-template-columns: 120px auto;
  grid-template-rows: 25px 25px 20 auto auto;
  color: #b5b5c3;
  font-size: 1.1em;

  & > .userName {
    font-size: 1rem;
    color: #3f4254;
  }

  .userEmail {
    font-size: 0.8rem;
    position: relative;
    bottom: 8px;
  }
`;

export const Image = styled.div`
  padding: 8px 0;
  height: 100%;
  width: 100%;
  grid-row-start: 1;
  grid-row-end: 5;
  position: relative;

  & > img {
    display: block;
    max-width: 102px;
    max-height: 102px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 3px solid ${theme.colors.borderProfile};
    object-fit: cover;
  }

  & > img:hover {
    cursor: pointer;
  }

  & > img:hover ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 30px;
    background-color: black;
    cursor: pointer;
  }
`;

export const Logout = styled.button`
  background-color: #bf9000;
  border: none;
  color: white;
  border-radius: 8px;
  width: 100%;
  height: 33px;
  align-self: bottom;
`;
