import axios, { AxiosError } from "axios";

export const urlRequestApi = "https://api.santher.tradexbip.com/";

const api = axios.create({
  baseURL: urlRequestApi,
});

api.interceptors.response.use(
  (success) => {
    return success;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      return Promise.resolve(() => {
        localStorage.removeItem("AccessTokenBipWeb");
        window.location.reload();
        api.defaults.headers.Authorization = "";
      });
    }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);

export default api;
