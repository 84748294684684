export const theme = {
  colors: {
    sideBar: "#000040",
    header: "#000000",
    subMenu: "#ffffff",
    profile: "#a144ac",
    borderProfile: "#0068b7",
    text: "#ffffff",
    button: "#0068b7",
    border: "#004e4a",
    numberCards: "#0068b7",
    arrow: "#000000",
    profileSideBar: "#000000",
    textHeaderMenu: "#ffffff",
    borderHeaderMenu: "#ffffff",
  },
  graphics: {
    light: "#cfcfcf",
    dark: "#6d6d6d",
    period: "#c00000",
    target: "#c00000",
  },
  scrollColor: "#012e51",
};
