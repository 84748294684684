/* eslint-disable camelcase */
import {
  Box,
  Flex,
  Grid,
  Image,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

// components
import { VscFile } from "react-icons/vsc";
import Card from "../../../../components/Card";
import { GlobalProps } from "../../../../store";
import {
  filter,
  stateMarketShare,
} from "../../../../store/SideFilters/RequestFilters/types";
import {
  IFilter,
  INITIAL_FILTERS,
  IProductFilter,
  IStateFilters,
  TPromoFilter,
} from "../../../../store/SideFilters/types";
import { SubItem } from "../../../../components/Contents/subMenu";
import HeaderTable from "../../../../components/Table/Header";
import HeaderGraphic from "../../../../components/Graphics/HeaderGraphic";
import Column from "../../../../components/Table/Column";

// Images
import binoculars from "../../../../assets/subMenu/binoculars_black.png";
import productBlackIcon from "../../../../assets/OnePage/products_black.png";
// import storeBlackIcon from "../../../../assets/OnePage/store_black.png";
// import subChannelBlackIcon from "../../../../assets/OnePage/basket_black.png";
// import bannerBlackIcon from "../../../../assets/OnePage/store_black.png";
// import regionBlackIcon from "../../../../assets/OnePage/map_black.png";

// graphics
import MarketSharePeriod from "../../../../components/Graphics/MarketSharePeriodLtyYtd/MarketSharePeriodLtyYtd";
import MarketSharePeriodBarLtyYtd from "../../../../components/Graphics/MarketSharePeriodBarLtyYtd/MarketSharePeriodBarLtyYtd";
import MarketShareValue from "../../../../components/Graphics/MarketShareValue/MarketShareValue";
import Waterfall from "../../../../components/Graphics/Waterfall";
import { RenderList } from "../../../../components/Contents/Resources/SubView/subView";
import { filterComponent } from "../../../../store/Navigation/types";

// Action redux
import * as ANavigator from "../../../../store/Navigation/actions";
import * as AFilters from "../../../../store/SideFilters/actions";

// configuration platform
import { useSelloutMarketEvolution } from "../../../../services/Hooks/OnePage/useSelloutMarketEvolution";
import { useSelloutIndustryEvolution } from "../../../../services/Hooks/OnePage/useSelloutIndustryEvolution";
import { useIndustryMarketEvolution } from "../../../../services/Hooks/OnePage/useIndustryMarketEvolution";
import {
  IParamsMKTShareContribution,
  useMarketShareContribution,
} from "../../../../services/Hooks/OnePage/useMarketShareContribution";
import { removeArrayIndex } from "../../../../components/Graphics/Resource/GraphicsFunctions";
import TemporalFilter, {
  ESubContribuition,
} from "../../../../components/TemporalFilter";
import { theme } from "../../../../styles/theme";
import FiltersList from "../../../../components/SideFilterMenu/Resource/FiltersList";
import LoadingComponent from "../../../../components/LoadingComponent";
import LegendIcon, { ETypeLegend } from "../../../../components/LegendIcon";
import {
  IMultipleFilter,
  useExecutionIndicator,
} from "../../../../services/Hooks/OnePage/useExecutionIndicator";
import { usePerformanceCategories } from "../../../../services/Hooks/OnePage/usePerformanceCategories";
import { getFirstItemList } from "../../../../Resource/functions";
import { ConfigContext } from "../../../../services/Context/ConfigContext";
import CardSubMenu from "../../../../components/CardSubMenu";

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DNavigator: {
    changeFilters(filters: filterComponent[]): void;
  };

  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
    changeCnpjStore(payload: string): void;
  };
}

interface IInternalFilter {
  evolutionIndustry: IMultipleFilter;
  MKTContribution: IFilter;
  executionIndicators: IFilter;
}

enum EContribuition {
  PRODUCT = "Categoria",
  SUB_CHANNEL = "Sub Channel",
  BANNER = "Banner",
  REGION = "Região",
}

export enum ESellOutFilter {
  VALUE = "value",
  QUANTITY = "amount",
}

type props = IDispatchProps & IStateProps;

const ContentOnePage: React.FC<props> = ({
  DNavigator,
  SAllFilters,
  SFilters,
  DFilters,
}: props) => {
  // Hooks
  const { config } = useContext(ConfigContext);

  const [contribuition, setContribuition] = useState<EContribuition>(
    EContribuition.PRODUCT
  );
  const [filterSellOut, setFilterSellOut] = useState<string>(
    ESellOutFilter.VALUE
  );
  const [internalFilter, setInternalFilter] = useState<IInternalFilter>({
    evolutionIndustry: {
      brand: {
        id: "",
        label: "",
      },
      segment: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: [],
      },
    },
    MKTContribution: {
      id: "",
      label: "",
    },
    executionIndicators: {
      id: "",
      label: "",
    },
  });

  const [mktShareContribution, setMktShareContribution] =
    useState<IParamsMKTShareContribution>({
      banner: "",
      period: {
        id: ESubContribuition.LP,
        label: ESubContribuition.LP,
      },
      region: "",
      subChannel: "",
      uf: "",
    });

  // Request data the API

  const marketEvolution = useSelloutMarketEvolution(SFilters, filterSellOut);
  const industryEvolution = useSelloutIndustryEvolution(
    SFilters,
    internalFilter.evolutionIndustry,
    filterSellOut
  );
  const marketIndustry = useIndustryMarketEvolution(SFilters, filterSellOut);
  const executionIndicator = useExecutionIndicator(
    SFilters,
    internalFilter.executionIndicators
  );
  const marketShareContribution = useMarketShareContribution(
    mktShareContribution.period.id,
    SFilters,
    internalFilter.MKTContribution,
    filterSellOut
  );
  const performaceCategory = usePerformanceCategories(SFilters, filterSellOut);

  const setTemporalFilter = (filterSelected: filter) => {
    setMktShareContribution({
      ...mktShareContribution,
      period: filterSelected,
    });
  };

  // Render filter
  const renderFilter = useCallback(() => {
    if (config.showSideFilters.myExecution)
      DNavigator.changeFilters(
        FiltersList(
          SAllFilters,
          SFilters,
          {
            ...INITIAL_FILTERS,
            businessUnitId: true,
            category: true,
            subCategory: true,
            subChannel: true,
            banner: true,
            region: true,
            uf: true,
            merchandising: true,
            storeCNPJ: true,
            // commercialStructureDirector: true,
            // commercialStructureManager: true,
            // commercialStructureSales: true,
          },
          DFilters
        )
      );
  }, [SAllFilters, SFilters, DNavigator]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter]);

  // const filterBrandSO = (e: ChangeEvent<HTMLSelectElement>): void => {
  //   const convertToObject: IFilter = JSON.parse(e.currentTarget.value);

  //   const filtredItens =
  //     SAllFilters.data.myExecution.subviewOSA.secondary.segments
  //       .filter((filterItem) => convertToObject.id === filterItem.brandId)
  //       .map((filtred) => {
  //         return filtred;
  //       });

  //   setInternalFilter({
  //     ...internalFilter,
  //     evolutionIndustry: {
  //       brand: convertToObject,
  //       segment: {
  //         selectedFilterId: "",
  //         selectedFilterName: "",
  //         listFilters: filtredItens || [],
  //       },
  //     },
  //   });
  // };

  // const filterSegmentSO = (e: ChangeEvent<HTMLSelectElement>): void => {
  //   const convertToObject: IFilter = JSON.parse(e.currentTarget.value);

  //   setInternalFilter({
  //     ...internalFilter,
  //     evolutionIndustry: {
  //       brand: internalFilter.evolutionIndustry.brand,
  //       segment: {
  //         selectedFilterId: convertToObject.id,
  //         selectedFilterName: convertToObject.label,
  //         listFilters: internalFilter.evolutionIndustry.segment.listFilters,
  //       },
  //     },
  //   });
  // };

  // const filterBrandMTKContribution = (
  //   e: ChangeEvent<HTMLSelectElement>
  // ): void => {
  //   const convertToObject: IFilter = JSON.parse(e.currentTarget.value);

  //   setInternalFilter({
  //     ...internalFilter,
  //     MKTContribution: convertToObject,
  //   });
  // };

  // const filterBrandIndicator = (e: ChangeEvent<HTMLSelectElement>): void => {
  //   const convertToObject: IFilter = JSON.parse(e.currentTarget.value);

  //   setInternalFilter({
  //     ...internalFilter,
  //     executionIndicators: convertToObject,
  //   });
  // };

  return (
    <Grid templateRows="repeat(4, 1fr)" h="100%" gridRowGap="5px">
      <CardSubMenu
        headers={[
          {
            title: "Sell Out | Evolução do Mercado",
          },
          {
            title: "Sell Out | Evolução da CIMED",
          },
        ]}
        subItens={[
          { id: ESellOutFilter.VALUE, label: "Valor" },
          { id: ESellOutFilter.QUANTITY, label: "Volume" },
        ]}
        selectedPeriod={setFilterSellOut}
      >
        <>
          <Stack w="100%" justify="flex-end" bg="white">
            {!marketEvolution.isLoading && marketEvolution.data ? (
              <Flex justifyContent="center" w="100%">
                <Grid
                  templateColumns="min-content min-content"
                  columnGap="10px"
                  p="0 5px"
                  overflow="auto"
                >
                  <Stack alignItems="flex-start" justifyContent="space-between">
                    <Stack align="flex-end">
                      <MarketSharePeriod
                        lineWidth={false}
                        dataGraphic={marketEvolution.data.data.pl}
                        ml={11}
                        mr={9}
                      />
                      <MarketSharePeriodBarLtyYtd
                        dataGraphic={marketEvolution.data.data.pbl}
                      />
                    </Stack>
                  </Stack>
                  <Stack alignItems="flex-start" justifyContent="space-between">
                    <Stack align="flex-end">
                      <MarketSharePeriod
                        dataGraphic={marketEvolution.data.data.pr}
                      />
                      <MarketSharePeriodBarLtyYtd
                        dataGraphic={marketEvolution.data.data.pbr}
                        r={16}
                        l={15}
                        bgColor={theme.graphics.light}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Flex>
            ) : (
              <LoadingComponent />
            )}

            <Flex
              position="relative"
              justify="center"
              align="center"
              color="black"
              fontSize="12px"
            >
              <LegendIcon typeLegend={ETypeLegend.bar}>
                Mercado R$ (&lsquo;000)
              </LegendIcon>
              <LegendIcon ml="10px" typeLegend={ETypeLegend.period}>
                Var. vs AA%
              </LegendIcon>
            </Flex>
          </Stack>
          <Stack w="100%" bg="white">
            <Box color="black" w="100%" p="5px 7px">
              {/* <ListBox
                itens={[
                  {
                    id: "mtkEvolutionIndustryBrand",
                    optionSelect: SFilters.filter.brand.listFilters || [],
                    selected: internalFilter.evolutionIndustry.brand.id,
                    onChange: filterBrandSO,
                  },
                  {
                    id: "mtkEvolutionIndustrySegment",
                    optionSelect:
                      internalFilter.evolutionIndustry.segment.listFilters ||
                      [],
                    selected:
                      internalFilter.evolutionIndustry.segment.selectedFilterId,
                    onChange: filterSegmentSO,
                  },
                ]}
              /> */}
            </Box>
            {!industryEvolution.isLoading && industryEvolution.data ? (
              <Flex justifyContent="center" w="100%">
                <Grid
                  templateColumns="min-content min-content"
                  columnGap="10px"
                  p="0 5px"
                  overflow="auto"
                >
                  <Stack alignItems="flex-start" justifyContent="space-between">
                    <Stack align="flex-end">
                      <MarketSharePeriod
                        lineWidth={false}
                        dataGraphic={industryEvolution.data.data.pl}
                        ml={9}
                        mr={9}
                      />
                      <MarketSharePeriodBarLtyYtd
                        dataGraphic={industryEvolution.data.data.pbl}
                      />
                    </Stack>
                  </Stack>
                  <Stack alignItems="flex-start" justifyContent="space-between">
                    <Stack align="flex-end">
                      <MarketSharePeriod
                        dataGraphic={industryEvolution.data.data.pr}
                      />
                      <MarketSharePeriodBarLtyYtd
                        dataGraphic={industryEvolution.data.data.pbr}
                        bgColor={theme.graphics.light}
                        r={16}
                        l={15}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Flex>
            ) : (
              <LoadingComponent />
            )}

            <Flex
              position="relative"
              justify="center"
              align="center"
              color="black"
              fontSize="12px"
            >
              <LegendIcon typeLegend={ETypeLegend.bar}>
                {`${config.general.industryName} R$ ('000)`}
              </LegendIcon>
              <LegendIcon ml="10px" typeLegend={ETypeLegend.period}>
                Var. vs AA%
              </LegendIcon>
            </Flex>
          </Stack>
        </>
      </CardSubMenu>
      <Grid gap="5px" templateColumns="calc(60% - 5px) 40%">
        <Card title="Market Share | Performance Categorias" mr="5px">
          <Stack
            overflow="auto"
            css={`
              &::-webkit-scrollbar {
                background-color: darkgray;
                border-radius: 20px;
                width: 12px;
                height: 12px;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 20px;
                background-color: ${theme.scrollColor};
              }
            `}
            w="100%"
          >
            {(performaceCategory.isLoading && <LoadingComponent />) || (
              <Flex pb="3px" mx="5px">
                <Table
                  color="black"
                  fontWeight="bold"
                  style={{ borderCollapse: "separate" }}
                  fontSize="12px"
                >
                  <Tbody>
                    <Tr>
                      <HeaderTable rowSpan={2} mw="135px">
                        Categorias
                      </HeaderTable>
                      <HeaderTable colSpan={3}>Crescimento</HeaderTable>
                      <HeaderTable colSpan={3}>Market Share</HeaderTable>
                      <HeaderTable colSpan={3}>% Var. Market Share</HeaderTable>
                    </Tr>
                    <Tr>
                      <HeaderTable mw="80px">YTD</HeaderTable>
                      <HeaderTable mw="80px">U3M</HeaderTable>
                      <HeaderTable mw="80px">LP</HeaderTable>
                      <HeaderTable mw="80px">YTD</HeaderTable>
                      <HeaderTable mw="80px">U3M</HeaderTable>
                      <HeaderTable mw="80px">LP</HeaderTable>
                      <HeaderTable mw="80px">YTD</HeaderTable>
                      <HeaderTable mw="80px">U3M</HeaderTable>
                      <HeaderTable mw="80px">LP</HeaderTable>
                    </Tr>
                    <Tr>
                      <HeaderTable
                        color="#000000"
                        bg={theme.graphics.light}
                        mw="80px"
                      >
                        {performaceCategory.data?.header?.industry || "------"}
                      </HeaderTable>
                      {performaceCategory.data?.header?.values.map(
                        (value, index) => (
                          <HeaderTable
                            key={`${value}-${index.toString()}`}
                            color="#000000"
                            bg={theme.graphics.light}
                            mw="80px"
                          >
                            {value}
                          </HeaderTable>
                        )
                      )}
                    </Tr>
                    <Tr>
                      <Td p={1} colSpan={13}>
                        <hr style={{ background: "#A5A5A5" }} />
                      </Td>
                    </Tr>
                    {performaceCategory.data?.content?.map((performance) => (
                      <Tr key={performance.category}>
                        <Column
                          mw="135px"
                          isTextCenter={false}
                          value={performance.category}
                        />
                        {performance.values.map((value, idx) => (
                          <Column
                            key={`${value}-${idx.toString()}`}
                            mw="80px"
                            value={value}
                          />
                        ))}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>
            )}
          </Stack>
        </Card>
        <Card
          title={`Market Share | Evolução da ${config.general.industryName}`}
        >
          <Stack w="100%">
            {/* <Flex justifyContent="space-between">
              <SeeDetailButton
                icon={productBlackIcon}
                label="Ver detalhe Produto"
                goPage={INavContent.MS_BANNER_DATAIL_PRODUCT}
              />
              <SeeDetailButton
                icon={storeBlackIcon}
                label="Ver detalhe Loja"
                goPage={INavContent.MS_BANNER_DATAIL_STORE}
              />
            </Flex> */}
            {(marketIndustry.isLoading && <LoadingComponent />) || (
              <Stack alignItems="flex-end" justifyContent="space-between">
                <MarketSharePeriod dataGraphic={marketIndustry.data?.p} />
                <MarketSharePeriodBarLtyYtd
                  dataGraphic={marketIndustry.data?.pb}
                  bgColor={theme.graphics.light}
                  l={16}
                  r={15}
                />
              </Stack>
            )}

            <Flex
              position="relative"
              justify="center"
              align="center"
              color="black"
              fontSize="12px"
            >
              <LegendIcon typeLegend={ETypeLegend.bar}>MS% 2022</LegendIcon>
              <LegendIcon ml="10px" typeLegend={ETypeLegend.period}>
                MS% 2021
              </LegendIcon>
            </Flex>
          </Stack>
        </Card>
      </Grid>
      <Stack color="white">
        <Flex h="45px">
          <Flex
            w="100%"
            justify="center"
            align="center"
            bg="cardHeaderBackgroundColor"
          >
            <Text fontSize="20px" fontWeight="bold">
              Contribuição no Market Share
            </Text>
          </Flex>
        </Flex>
        <Flex h="80px" mt="5px !important" bg="subHeaderColor" align="center">
          <Flex
            h="57px"
            outline="0"
            align="center"
            justify="center"
            borderRight="2px solid #e2e2e2"
            w="145px"
          >
            <Image src={binoculars} alt="icon Binoculars" w="65px" />
          </Flex>
          <SubItem
            icon={productBlackIcon}
            active={contribuition === EContribuition.PRODUCT}
            onClick={() => setContribuition(EContribuition.PRODUCT)}
          />
          {/* <SubItem
            icon={subChannelBlackIcon}
            active={contribuition === EContribuition.SUB_CHANNEL}
            onClick={() => setContribuition(EContribuition.SUB_CHANNEL)}
          />
          <SubItem
            icon={bannerBlackIcon}
            active={contribuition === EContribuition.BANNER}
            onClick={() => setContribuition(EContribuition.BANNER)}
          />
          <SubItem
            icon={regionBlackIcon}
            active={contribuition === EContribuition.REGION}
            onClick={() => setContribuition(EContribuition.REGION)}
          /> */}
        </Flex>
        <Flex mt="5px !important">
          <Flex w="100%" bg="white">
            {(marketShareContribution.isLoading && <LoadingComponent />) || (
              <Stack mr="5px" w="100%" mb="5px">
                <Flex justifyContent="space-between" mb="17px">
                  <TemporalFilter
                    setTemporalFilter={setTemporalFilter}
                    temporalFilter={mktShareContribution.period}
                    itens={[
                      // "MAT",
                      // "YTD",
                      // "U3M",
                      { id: "LP", label: "LP" },
                    ]}
                  />

                  {/* <Box color="black" w="340px" mt="0 !important" p="5px 7px">
                    <ListBox
                      justify="end"
                      itens={
                        (contribuition === EContribuition.PRODUCT && [
                          {
                            id: "contributionMKTBrand",
                            optionSelect:
                              SFilters.filter.brand.listFilters || [],
                            selected: internalFilter.MKTContribution.id,
                            onChange: filterBrandMTKContribution,
                          },
                        ]) ||
                        (contribuition === EContribuition.SUB_CHANNEL && [
                          {
                            optionSelect: subChannel,
                            selected: "",
                            id: "sc",
                            onChange: () => console.log("lol"),
                          },
                        ]) ||
                        (contribuition === EContribuition.BANNER && [
                          {
                            optionSelect: subChannel,
                            selected: "",
                            id: "scbanner",
                            onChange: () => console.log("lol"),
                          },
                        ]) || [
                          {
                            optionSelect: region,
                            selected: "",
                            id: "region",
                            onChange: () => console.log("lol"),
                          },
                        ]
                      }
                    />
                  </Box> */}
                </Flex>
                <Stack w="100%" bg="white" mr="5px" mt="0 !important">
                  {((mktShareContribution.period.id === ESubContribuition.YTD ||
                    mktShareContribution.period.id === "U3M") && (
                    <Stack
                      color="#7A7A7A"
                      w="100%"
                      h="100%"
                      justify="center"
                      align="center"
                      fontWeight="bold"
                      fontSize="17px"
                      minH="350px"
                    >
                      <VscFile size={35} />
                      <Text mt="10px !important">
                        Não existe dados para esse periodo!
                      </Text>
                    </Stack>
                  )) || (
                    <Grid
                      templateColumns="repeat(4, 1fr)"
                      columnGap={2}
                      w="100%"
                      color="black"
                      px="5%"
                    >
                      <Stack>
                        <Text
                          fontStyle="italic"
                          px="5px"
                          fontSize="16px"
                          whiteSpace="nowrap"
                          mb="7px"
                        >
                          % Contribuição {contribuition} (pp)
                        </Text>
                        <Box mt="0 !important">
                          <Waterfall
                            dataGraphic={
                              marketShareContribution.data
                                ?.marketShareContribution.waterfall
                            }
                          />
                        </Box>
                      </Stack>
                      <Stack>
                        <Text
                          px="5px"
                          fontSize="16px"
                          whiteSpace="nowrap"
                          textAlign="center"
                          fontStyle="italic"
                          mb="10px"
                        >
                          % Market Share
                        </Text>
                        <HeaderGraphic fs="16px" mb="4px !important">
                          {getFirstItemList(
                            marketShareContribution.data
                              ?.marketShareContribution.msValue?.label
                          )}
                        </HeaderGraphic>
                        <RenderList
                          background="#AFABAB"
                          fontSize={config.fonts.graphics}
                          fontColor="white"
                          margin="0"
                          padding="0.3"
                          data={removeArrayIndex(
                            marketShareContribution.data
                              ?.marketShareContribution.msValue
                          )}
                        />
                      </Stack>
                      <Stack>
                        <Text
                          px="5px"
                          fontSize="16px"
                          whiteSpace="nowrap"
                          textAlign="center"
                          fontStyle="italic"
                          mb="10px"
                        >
                          % Var. Market Share
                        </Text>
                        <HeaderGraphic fs="16px">
                          {marketShareContribution.data?.marketShareContribution
                            .varMarketShare.label[0] || "-----"}
                        </HeaderGraphic>
                        <Box mt="-1px !important">
                          <MarketShareValue
                            dataGraphic={removeArrayIndex(
                              marketShareContribution.data
                                ?.marketShareContribution.varMarketShare
                            )}
                          />
                        </Box>
                      </Stack>
                      <Stack>
                        <Text
                          px="5px"
                          whiteSpace="nowrap"
                          fontSize="16px"
                          textAlign="center"
                          fontStyle="italic"
                          mb="10px"
                        >
                          % Var. Sell Out (R$)
                        </Text>
                        <HeaderGraphic fs="16px">
                          {marketShareContribution.data?.marketShareContribution
                            .varSellOut.label[0] || "-----"}
                        </HeaderGraphic>
                        <Box mt="-1px !important">
                          <MarketShareValue
                            dataGraphic={removeArrayIndex(
                              marketShareContribution.data
                                ?.marketShareContribution.varSellOut
                            )}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  )}
                </Stack>
                {/* {(contributionBrands.isLoading && <LoadingComponent />) ||
                  (contributionBrands.isError && (
                    <Stack
                      color="#7A7A7A"
                      w="100%"
                      h="100%"
                      justify="center"
                      align="center"
                      fontWeight="bold"
                      fontSize="17px"
                      minH="350px"
                    >
                      <IoCloudOfflineOutline size={40} />
                      <Text mt="10px !important">
                        Não foi possivel trazer os dados do servidor!
                      </Text>
                    </Stack>
                  )) || (
                    <Flex>
                      <Table
                        w="100%"
                        mt="5px !important"
                        style={{ borderCollapse: "separate" }}
                      >
                        <Thead>
                          <Tr>
                            {contributionBrands.data?.header
                              .slice(0, -1)
                              .map((header) => (
                                <HeaderTable>
                                  {header || "-------------"}
                                </HeaderTable>
                              ))}
                          </Tr>
                        </Thead>
                        <Tbody color="black">
                          {contributionBrands.data?.content.map(
                            (values, index) => (
                              <Tr>
                                {index === 0 && (
                                  <Td
                                    h="30px"
                                    p="0"
                                    rowSpan={
                                      contributionBrands.data?.content.length
                                    }
                                    border="1px solid #E7E6E6"
                                  >
                                    <Flex justify="center" align="center">
                                      <Image
                                        src={contributionBrands.data?.image}
                                        width="120px"
                                        alt="icon segment"
                                      />
                                    </Flex>
                                  </Td>
                                )}
                                {values.map((columns, idx) => (
                                  <Column isTextCenter={idx !== 0}>
                                    {columns}
                                  </Column>
                                ))}
                              </Tr>
                            )
                          )}
                        </Tbody>
                      </Table>
                      <Stack ml="1px" pt="7px" minW="150px">
                        <HeaderGraphic>
                          {mockContribution.header[
                            mockContribution.header.length - 1
                          ] || "-------------"}
                        </HeaderGraphic>
                        <Stack mt="0px !important">
                          <MarketShareValue
                            dataGraphic={contributionBrands.data?.growth}
                            marginTop={0}
                          />
                        </Stack>
                      </Stack>
                    </Flex>
                  )} */}
              </Stack>
            )}
          </Flex>
        </Flex>
      </Stack>
      <Grid templateColumns="1fr">
        <Card bg="transparent" title="Indicadores de Execução">
          <Stack w="100%">
            <Flex gap="5px" w="100%" overflow="auto" position="relative">
              <Stack
                bg="white"
                p="10px"
                w="100%"
                minW="329px"
                position="sticky"
                left={0}
              >
                <Table style={{ borderCollapse: "separate" }}>
                  <Thead h="64px">
                    <Tr>
                      <HeaderTable colSpan={2} color="#C00000" fs="14px">
                        Categoria
                      </HeaderTable>
                    </Tr>
                  </Thead>
                  <Tbody color="black">
                    {(executionIndicator.isLoading && <LoadingComponent />) ||
                      executionIndicator.data?.categories.names.map(
                        (sement, index) => {
                          return (
                            <Tr key={sement + index.toString()}>
                              {(index === 0 && (
                                <>
                                  <Td
                                    h="31px"
                                    p="0"
                                    rowSpan={
                                      executionIndicator.data?.categories.names
                                        .length
                                    }
                                    border="1px solid #E7E6E6"
                                  >
                                    <Image
                                      margin="auto"
                                      src={
                                        executionIndicator.data?.categories
                                          .image
                                      }
                                      alt="Icon Marca"
                                      width="75px"
                                    />
                                  </Td>
                                  <Column isTextCenter={false} value={sement} />
                                </>
                              )) || (
                                <Column isTextCenter={false} value={sement} />
                              )}
                            </Tr>
                          );
                        }
                      )}
                  </Tbody>
                </Table>
              </Stack>
              <Stack bg="white" p="10px" w="100%" minW="329px">
                <Text
                  bg="#E7E6E6"
                  y="5px"
                  color="#C00000"
                  textAlign="center"
                  fontWeight="bold"
                  fontSize="14px"
                  mx="2px"
                  mt="2px"
                  h="31px"
                  lineHeight="31px"
                >
                  Presença (Itens Chave)
                </Text>
                <Table mt="0 !important" style={{ borderCollapse: "separate" }}>
                  <Thead color="black">
                    <Tr>
                      <HeaderTable>MAT</HeaderTable>
                      <HeaderTable>YTD</HeaderTable>
                      <HeaderTable>U3M</HeaderTable>
                      <HeaderTable>LP</HeaderTable>
                    </Tr>
                  </Thead>
                  <Tbody color="black">
                    {(executionIndicator.isLoading && (
                      <Td colSpan={4}>
                        <LoadingComponent />
                      </Td>
                    )) ||
                      executionIndicator.data?.indicators.presences.map(
                        (presence) => {
                          return (
                            <Tr key={Math.random()}>
                              <Column value={presence.mat} />
                              <Column value={presence.ytd} />
                              <Column value={presence.U3M} />
                              <Column value={presence.lp} />
                            </Tr>
                          );
                        }
                      )}
                  </Tbody>
                </Table>
              </Stack>
              <Stack bg="white" p="10px" w="100%" minW="329px">
                <Text
                  bg="#E7E6E6"
                  y="5px"
                  color="#C00000"
                  textAlign="center"
                  fontWeight="bold"
                  fontSize="14px"
                  mx="2px"
                  mt="2px"
                  h="31px"
                  lineHeight="31px"
                >
                  Gôndola (Share of Shelf)
                </Text>
                <Table mt="0 !important" style={{ borderCollapse: "separate" }}>
                  <Thead color="black">
                    <Tr>
                      <HeaderTable>MAT</HeaderTable>
                      <HeaderTable>YTD</HeaderTable>
                      <HeaderTable>U3M</HeaderTable>
                      <HeaderTable>LP</HeaderTable>
                    </Tr>
                  </Thead>
                  <Tbody color="black">
                    {(executionIndicator.isLoading && (
                      <Td colSpan={4}>
                        <LoadingComponent />
                      </Td>
                    )) ||
                      executionIndicator.data?.indicators.sos.map((price) => {
                        return (
                          <Tr key={Math.random()}>
                            <Column value={price.mat} />
                            <Column value={price.ytd} />
                            <Column value={price.U3M} />
                            <Column value={price.lp} />
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </Stack>
              <Stack bg="white" p="10px" w="100%" minW="329px">
                <Text
                  bg="#E7E6E6"
                  y="5px"
                  color="#C00000"
                  textAlign="center"
                  fontWeight="bold"
                  fontSize="14px"
                  mx="2px"
                  mt="2px"
                  h="31px"
                  lineHeight="31px"
                >
                  Pontos Extras (Share of visibility)
                </Text>
                <Table mt="0 !important" style={{ borderCollapse: "separate" }}>
                  <Thead color="black">
                    <Tr>
                      <HeaderTable>MAT</HeaderTable>
                      <HeaderTable>YTD</HeaderTable>
                      <HeaderTable>U3M</HeaderTable>
                      <HeaderTable>LP</HeaderTable>
                    </Tr>
                  </Thead>
                  <Tbody color="black">
                    {(executionIndicator.isLoading && (
                      <Td colSpan={4}>
                        <LoadingComponent />
                      </Td>
                    )) ||
                      executionIndicator.data?.indicators.extra_exposure.map(
                        (spiner) => {
                          return (
                            <Tr key={Math.random()}>
                              <Column value={spiner.mat} />
                              <Column value={spiner.ytd} />
                              <Column value={spiner.U3M} />
                              <Column value={spiner.lp} />
                            </Tr>
                          );
                        }
                      )}
                  </Tbody>
                </Table>
              </Stack>
              {/* <Stack bg="white" p="10px" w="100%" minW="329px">
              <Text
                bg="#E7E6E6"
                y="5px"
                color="#C00000"
                textAlign="center"
                fontWeight="bold"
                fontSize="14px"
                mx="2px"
                mt="2px"
                h="31px"
              >
                Preço (Aderência)
              </Text>
              <Table mt="0 !important" style={{ borderCollapse: "separate" }}>
                <Thead color="black">
                  <Tr>
                    <HeaderTable>MAT</HeaderTable>
                    <HeaderTable>YTD</HeaderTable>
                    <HeaderTable>U3M</HeaderTable>
                    <HeaderTable>LP</HeaderTable>
                  </Tr>
                </Thead>
                <Tbody color="black">
                  {indicatorsValue.gondolas.map((gondola, index) => {
                    return (
                      (index === indicatorsValue.gondolas.length - 1 && (
                        <Tr key={Math.random()}>
                          <Column isBold bg="#E7E6E6">
                            {gondola.mat}
                          </Column>
                          <Column isBold bg="#E7E6E6">
                            {gondola.ytd}
                          </Column>
                          <Column isBold bg="#E7E6E6">
                            {gondola.U3M}
                          </Column>
                          <Column isBold bg="#E7E6E6">
                            {gondola.lp}
                          </Column>
                        </Tr>
                      )) || (
                        <Tr key={Math.random()}>
                          <Column>{gondola.mat}</Column>
                          <Column>{gondola.ytd}</Column>
                          <Column>{gondola.U3M}</Column>
                          <Column>{gondola.lp}</Column>
                        </Tr>
                      )
                    );
                  })}
                </Tbody>
              </Table>
            </Stack> */}
            </Flex>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DFilters: bindActionCreators(AFilters, dispatch),
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentOnePage);
