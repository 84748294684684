import { CgClose } from "react-icons/cg";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  ReactEventHandler,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { TbHandClick } from "react-icons/tb";
import { ImFilePdf } from "react-icons/im";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button, Flex, Grid, Image, Stack, Text } from "@chakra-ui/react";
import { BiBrushAlt } from "react-icons/bi";

import calandarImg from "../../../../assets/img/icons/calendar.png";
import cartImg from "../../../../assets/img/icons/cart.png";
import storeImg from "../../../../assets/img/icons/small_store.png";
import graphicBrazilImg from "../../../../assets/MyDay/mapa.png";
import basketImg from "../../../../assets/img/icons/cesta.png";
import drugstoreImg from "../../../../assets/img/icons/farmacia.png";
import iconPDF from "../../../../assets/img/icons/pdf.png";
// import iconPPTX from "../../../../assets/img/icons/powerpoint.png";

import { CardExecution } from "./CardExecution";
import PDFBook from "./ExportPDF/generatePDF";
import { GlobalProps } from "../../../../store";
import {
  INITIAL_FILTERS,
  IProductFilter,
  IStateFilters,
  TPromoFilter,
} from "../../../../store/SideFilters/types";
import {
  TGroupedPayload,
  useFetchEvidences,
} from "../../../../services/Hooks/Evidences";
import { FormatStringDataBr } from "../../../../components/Graphics/Resource/GraphicsFunctions";

// Actions
import * as ANavigation from "../../../../store/Navigation/actions";
import * as AFilters from "../../../../store/SideFilters/actions";

// types
import { filterComponent } from "../../../../store/Navigation/types";
import { stateMarketShare } from "../../../../store/SideFilters/RequestFilters/types";

// config system
import FiltersList from "../../../../components/SideFilterMenu/Resource/FiltersList";
import LoadingComponent from "../../../../components/LoadingComponent";
import Card from "../../../../components/Card";
import { theme } from "../../../../styles/theme";
import { BookCardContainer, PaginatorL, PaginatorR } from "./ViewBook.elements";
import { ConfigContext } from "../../../../services/Context/ConfigContext";
import { UModalCalendar } from "../../MyNegotiations/ViewCalendar/ModalCalendar/styles";
import {
  ModalOverlay,
  ModalWrapper,
} from "../../../../components/Modal/styles";
import { IContentPlanogram, IFormatPlanogram } from "./types";
import Toast, { useToast } from "../../../../components/Toast";
import { filterSubChannel } from "../../../../components/SideFilterMenu/Resource";

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
    changeCnpjStore(payload: string): void;
  };

  DNavigation: {
    changeFilters(filters: filterComponent[]): void;
  };
}

type props = IStateProps & IDispatchProps;

const ViewBook: React.FC<props> = ({
  DNavigation,
  SFilters,
  SAllFilters,
  DFilters,
}: props) => {
  const [atualPage, setAtualPage] = useState<number>(1);
  const [index, setIndex] = useState<number>(0);
  const [numberPages, setNumberPages] = useState<number>(0);
  const [popUpExportPDF, setPopUpExportPDF] = useState<boolean>(false);
  const [listExport, setListExport] = useState<IFormatPlanogram[]>([]);
  const { showToast, setShowToast } = useToast();

  const [evidences, setEvidences] = useState<TGroupedPayload[]>();

  const handlePaginationUp: ReactEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    console.log(atualPage);
    if (atualPage !== numberPages) {
      setAtualPage(atualPage + 1);
      setIndex(index + 6);
    }
  };

  const handlePaginationDown: ReactEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    console.log(atualPage)
    if (atualPage !== 1) {
      setAtualPage(atualPage - 1);
      setIndex(index - 6);
    }
  };

  const countQtyPage = useCallback((amountItem: number) => {
    if (amountItem % 6 === 0) {
      setNumberPages(amountItem / 6);
    } else {
      const pages = Math.floor(amountItem / 6) + 1;
      setNumberPages(pages);
    }
  }, []);

  function mountObjectToExportation(stores: TGroupedPayload[][]) {
    const objectMonted: IFormatPlanogram[] = stores.map((store) => {
      let executionType = "";
      let banner = "";

      const planograms: IContentPlanogram[] = store.map((plan: any) => {
        banner = plan.storeBanner;
        executionType = plan.executionLeverage;

        return {
          imgBefore: plan.evidencesBefore,
          imgAfter: plan.evidencesAfter,
          evidenceExtraExposure: plan.evidences,
          executionLavarage: plan.executionLeverage,
          description: {
            store: plan.storeName,
            visited: plan.date,
            city: plan.storeCity,
            uf: plan.storeUf,
            brand: plan?.brand,
            banner: plan.storeBanner,
            promoter: plan.promoter,
          },
        };
      });

      return {
        banner,
        executionType,
        planogram: planograms,
      };
    });

    return objectMonted;
  }

  function groupingByBanner(
    bannerNames: string[],
    allStores: TGroupedPayload[]
  ): TGroupedPayload[][] {
    const storesGrouped = bannerNames.map((banner) => {
      return allStores
        .filter((evidence: TGroupedPayload) => evidence.storeBanner === banner)
        .map((filtred: TGroupedPayload) => {
          return filtred;
        });
    });

    return storesGrouped;
  }

  function groupingBannerName(allData: TGroupedPayload[]) {
    const bannerNames: string[] = [];
    allData.map((store) => {
      if (!bannerNames.includes(store.storeBanner))
        bannerNames.push(store.storeBanner);
      return null;
    });

    return bannerNames;
  }

  const getIndexItem = (indexArray: number) => {
    return index + indexArray + 1;
  };

  const { config } = useContext(ConfigContext);

  const { data, isLoading } = useFetchEvidences(
    SFilters,
    config.general.promoIndustryId
  );

  useEffect(() => {
    if (data && evidences?.length !== data?.evidences.length) {
      setEvidences(data.evidences);
      countQtyPage(data.evidences.length || 0);
      setAtualPage(1);
      setIndex(0);
    }
  }, [data, countQtyPage, evidences?.length]);

  const renderFilter = useCallback(() => {
    DNavigation.changeFilters(
      FiltersList(
        SAllFilters,
        SFilters,
        {
          ...INITIAL_FILTERS,
          period: true,
          typeExecution: true,
          executionLeverage: true,
          subChannel: true,
          businessUnitId: true,
          category: true,
          subCategory: true,
          banner: true,
          region: true,
          uf: true,
          isPromo: true,
          // commercialStructureDirector: true,
          // commercialStructureManager: true,
          // commercialStructureSales: true,
          storeCNPJ: true,
          retailEnviroment: false,
          gpdv: false,
        },
        DFilters
      )
    );
  }, [SAllFilters, SFilters, DNavigation]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter]);

  useEffect(() => {
    DFilters.changeFilterIsPromo({
      ...SFilters.promoFilter,
      banner: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: [],
      },
      subChannel: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: [],
      },
      retailEnviroment: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: [],
      },
    });
  }, []);

  const handleMountObjectForExtractPdf = async () => {
    if (numberPages > 200) {
      setShowToast();
      return;
    }

    if (evidences) {
      setPopUpExportPDF(true);
      const bannerNames = groupingBannerName(evidences);
      const storesGrouped = groupingByBanner(bannerNames, evidences);
      const dataForExtraction = mountObjectToExportation(storesGrouped);
      setListExport(dataForExtraction);
    }
  };

  return (
    <>
      <Toast
        {...{ showToast, setShowToast }}
        width="375px"
        margin="24px auto 0 auto"
        toastType="error"
        text="A quantidade de fotos excedida, máximo de 200 páginas por extração."
      />
      {popUpExportPDF && (
        <>
          <ModalOverlay className="remove" />
          <ModalWrapper className="remove">
            <UModalCalendar width="30vw" className="remove">
              {(listExport.length === 0 && (
                <LoadingComponent text="Preparando os dados para exportação!" />
              )) || (
                <Stack>
                  <Flex justifyContent="flex-end" cursor="pointer">
                    <CgClose
                      size={30}
                      color={theme.colors.button}
                      onClick={() => setPopUpExportPDF(false)}
                    />
                  </Flex>
                  <Stack w="100%" alignItems="center" p="50px 10px">
                    <PDFDownloadLink
                      document={
                        <PDFBook
                          data={listExport}
                          filters={{
                            initialDate: SFilters.promoFilter.dateInitial,
                            finishDate: SFilters.promoFilter.dateFinal,
                            subChannel:
                              SFilters.promoFilter.subChannel
                                .selectedFilterName,
                            banner:
                              SFilters.promoFilter.banner.selectedFilterName,
                            region:
                              SFilters.promoFilter.region.selectedFilterName,
                            uf: SFilters.promoFilter.uf.selectedFilterName,
                            category:
                              SFilters.promoFilter.category.selectedFilterName,
                            brandSelected:
                              SFilters.promoFilter.brand.selectedFilterName,
                            segmentSelected:
                              SFilters.promoFilter.segments.selectedFilterName,
                          }}
                          industryName={
                            config.general.industryName || "Sem industria"
                          }
                        />
                      }
                      fileName={`${
                        config.general.industryName.toLowerCase() ||
                        "Sem industria"
                      }-promo-book.pdf`}
                    >
                      {({ loading }) => {
                        return (
                          (loading && (
                            <LoadingComponent text="Gerando o book!" />
                          )) || (
                            <Stack alignItems="center">
                              <ImFilePdf size={60} color="red" />
                              <Button
                                cursor="pointer"
                                mt="40px !important"
                                h={8}
                                bg={theme.colors.button}
                              >
                                Download do book
                              </Button>
                            </Stack>
                          )
                        );
                      }}
                    </PDFDownloadLink>
                  </Stack>
                </Stack>
              )}
            </UModalCalendar>
          </ModalWrapper>
        </>
      )}
      <Grid templateRows="auto 1fr" h="calc(100% - 5px)">
        <Card title="Premissas do Calendário Promocional" bg="transparent">
          <Flex color="#7A7A7A" minH="160px" w="100%">
            <Flex bg="white" w="100%">
              <Stack ml="10px" w="calc(100% - 215px)">
                <Flex align="center">
                  <Text color={theme.colors.numberCards} fontSize="40px">
                    1
                  </Text>
                  <Image
                    src={calandarImg}
                    alt="Graphics"
                    w="32px"
                    h="32px"
                    mx="10px"
                  />
                  <Stack>
                    <Text>Periodo:</Text>
                    <Text as="span">
                      Selecione a data de <b>inicio e fim</b>
                    </Text>
                  </Stack>
                </Flex>
                <Stack mt="0px !important">
                  <Text>
                    <b>Período selecionado:</b>
                  </Text>
                  <Text as="span">
                    {`De ${FormatStringDataBr(
                      SFilters.promoFilter.dateInitial
                    )} até
                            ${FormatStringDataBr(
                              SFilters.promoFilter.dateFinal
                            )}`}
                  </Text>
                  <Text mt="5px !important">
                    <b>Praça Selecionada:</b>
                  </Text>
                  <Text as="span">
                    {`Região: ${FormatStringDataBr(
                      SFilters.promoFilter.region.selectedFilterName || "Todos"
                    )} | UF: ${FormatStringDataBr(
                      SFilters.promoFilter.uf.selectedFilterName || "Todos"
                    )}`}
                  </Text>
                </Stack>
              </Stack>
              <Flex mx="5px" justifyContent="end" align="center">
                <Image src={graphicBrazilImg} w="90%" h="85%" />
              </Flex>
            </Flex>
            <Flex bg="white" w="100%" mx="5px">
              <Stack ml="10px" w="calc(100% - 215px)">
                <Flex align="center">
                  <Text color={theme.colors.numberCards} fontSize="40px">
                    2
                  </Text>
                  <Image
                    src={cartImg}
                    alt="Graphics"
                    w="32px"
                    h="32px"
                    mx="10px"
                  />
                  <Stack>
                    <Text>Categoria:</Text>
                    <Text as="span">
                      <b>
                        {SFilters.promoFilter.category?.selectedFilterName ||
                          "Todas"}
                      </b>
                    </Text>
                  </Stack>
                </Flex>
                <Stack mt="12px !important">
                  <Text>
                    <b>Marcas Selecionadas:</b>
                  </Text>
                  <Text as="span">
                    {SFilters.promoFilter.brand.selectedFilterName || "Todos"}
                  </Text>
                </Stack>
              </Stack>
              <Flex mx="5px" justifyContent="end" align="center">
                <Image src={basketImg} w="90%" h="85%" />
              </Flex>
            </Flex>
            <Flex bg="white" w="100%">
              <Stack ml="10px" w="calc(100% - 215px)">
                <Flex align="center">
                  <Text color={theme.colors.numberCards} fontSize="40px">
                    3
                  </Text>
                  <Image
                    src={storeImg}
                    alt="Graphics"
                    w="32px"
                    h="32px"
                    mx="10px"
                  />
                  <Stack>
                    <Text>Canal:</Text>
                    <Text as="span">
                      <b>
                        {SFilters.promoFilter.subChannel?.selectedFilterName ||
                          "Todos"}
                      </b>
                    </Text>
                  </Stack>
                </Flex>
                <Stack mt="0px !important">
                  <Text>
                    <b>Bandeiras Selecionadas:</b>
                  </Text>
                  <Text as="span">
                    {SFilters.promoFilter.banner?.selectedFilterName || "Todas"}
                  </Text>
                  <Text mt="5px !important">
                    <b>Lojas:</b>
                  </Text>
                  <Text as="span">{data?.numberOfStores || "0"} Lojas</Text>
                </Stack>
              </Stack>
              <Flex mx="5px" justifyContent="end" align="center">
                <Image src={drugstoreImg} w="90%" h="85%" />
              </Flex>
            </Flex>
          </Flex>
        </Card>
        <Card title="Evidências das Execuções" mt="5px" bg="transparent">
          <Stack w="100%" h="100%">
            {(isLoading && (
              <Flex mt="0 !important" h="100%" w="100%" bg="white">
                <LoadingComponent />
              </Flex>
            )) ||
              (data?.evidences.length === 0 && (
                <Stack
                  mt="0 !important"
                  h="100%"
                  w="100%"
                  bg="white"
                  align="center"
                  justify="center"
                >
                  <BiBrushAlt size={45} color="#7A7A7A" />
                  <Text
                    mt="15px"
                    color="#7A7A7A"
                    fontWeight="bold"
                    fontSize="16px"
                  >
                    Não existe dados disponiveis para a sua seleção.
                  </Text>
                </Stack>
              )) ||
              (data?.evidences === undefined && (
                <Stack
                  w="100%"
                  h="100%"
                  bg="white"
                  align="center"
                  justify="center"
                >
                  <TbHandClick size={45} color="#7A7A7A" />
                  <Text mt="15px !important" color="#7A7A7A" fontSize="16px">
                    Selecione os filtros desejados e clique em <b>GERAR BOOK</b>
                  </Text>
                </Stack>
              )) || (
                <>
                  <Grid
                    bg="white"
                    justifyContent="space-between"
                    color="black"
                    h="55px"
                    templateColumns="repeat(3, 1fr)"
                  >
                    <Flex />
                    <Stack justify="center" align="center">
                      <Text>
                        <b>Exibindo:</b>
                      </Text>
                      <Text>{`${atualPage} de ${numberPages} páginas`}</Text>
                    </Stack>
                    <Flex align="center" justifyContent="end">
                      <b>exportar para:</b>
                      <Image
                        src={iconPDF}
                        alt="export to pdf"
                        width={45}
                        height={45}
                        onClick={() => handleMountObjectForExtractPdf()}
                        cursor="pointer"
                      />
                      {/* <img src={iconPPTX} alt="export to power point" /> */}
                    </Flex>
                  </Grid>
                  <BookCardContainer
                    width="100%"
                    margin="0 !important"
                    columns="repeat(3, 50px calc(100% - 105px) 50px)"
                    height="calc(100% - 60px)"
                  >
                    <PaginatorL onClick={(e) => handlePaginationDown(e)}>
                      <BsChevronCompactLeft />
                    </PaginatorL>

                    <BookCardContainer
                      columns="repeat(2, 1fr)"
                      rows={
                        evidences && evidences.length > 2
                          ? "repeat(2, 1fr)"
                          : "1fr"
                      }
                      rowGap="5px"
                      width="100%"
                    >
                      {evidences
                        ?.slice(index, index + 6)
                        .map((item: any, idx) => (
                          <CardExecution
                            objectNumber={getIndexItem(idx)}
                            before={item?.evidencesBefore || undefined}
                            after={item?.evidencesAfter || undefined}
                            evidenceExtraExposure={item?.evidences || undefined}
                            evidencesIr={item?.evidencesIr || undefined}
                            orderName={item.orderName}
                            cnpj={item.storeCnpj}
                            storeName={item.storeName}
                            banner={item.storeBanner}
                            uf={item.storeUf}
                            visitDate={item.date.split("-").reverse().join("/")} // formatar data para pt-br
                            brandName={item?.brand}
                            nameLever={item.executionLeverage}
                            key={Math.random()}
                          />
                        ))}
                    </BookCardContainer>

                    <PaginatorR onClick={(e) => handlePaginationUp(e)}>
                      <BsChevronCompactRight />
                    </PaginatorR>
                  </BookCardContainer>
                </>
              )}
          </Stack>
        </Card>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  DFilters: bindActionCreators(AFilters, dispatch),
  DNavigation: bindActionCreators(ANavigation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewBook);
