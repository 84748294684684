/* eslint-disable camelcase */
import { useQuery, UseQueryResult } from "react-query";
import api from "../../api";

export interface IFeedbackNotification {
  custumer_sales_rep: string;
  date: string;
  feedback: string;
  store: IFeedbackNotification[];
}

interface IResponseNotification {
  status: string;
  feedback: IFeedbackNotification[];
}

const requestNotification = async (): Promise<IResponseNotification> => {
  const response = await api.get<IResponseNotification>(
    "store_check_feedback/"
  );
  return response.data;
};

export const useRequestNotification = (): UseQueryResult<
  IResponseNotification,
  unknown
> => {
  return useQuery(["notification"], requestNotification, {
    refetchOnWindowFocus: false,
    retry: 2,
  });
};
