export enum menuTypes {
  CHANGE_MENU = "@Navigation/CHANGE_MENU",
  CHANGE_TITLE_MENU = "@Navigation/CHANGE_TITLE_MENU",
  CHANGE_SUBMENU = "@Navigation/CHANGE_SUBMENU",
  CHANGE_FILTERS = "@Navigation/CHANGE_FILTERS",
  CHANGE_PAGE = "@Navigation/CHANGE_PAGE",
  CHANGE_CONTENT = "@Navigation/CHANGE_CONTENT",
}

export enum INavPage {
  MAIN_PAGE = "@Navigation/MAIN_PAGE",
  LOGIN_PAGE = "@Navigation/LOGIN_PAGE",
}

export enum INavContent {
  MAIN_DASH_CONTENT = "@Navigation/MAIN_DASH_CONTENT",

  PROFILE_SETTINGS = "@Navigation/PROFILE_SETTINGS",

  MS_BANNER_DATAIL_PRODUCT = "@Navigation/MS_BANNER_DATAIL_PRODUCT",
  MS_BANNER_DATAIL_STORE = "@Navigation/MS_BANNER_DATAIL_STORE",

  PROMO_COMPLICE_STORE = "@Navigation/PROMO_COMPLICE_STORE",

  MSL_DETAIL_PRODUCT = "@Navigation/MSL_DETAIL_PRODUCT",
  MSL_DETAIL_STORE = "@Navigation/MSL_DETAIL_STORE",

  NPD_DETAIL_PRODUCT = "@Navigation/NPD_DETAIL_PRODUCT",
  ME_DETAIL_STORE = "@Navigation/ME_DETAIL_STORE",
  ME_DETAIL_KPI = "@Navigation/ME_DETAIL_KPI",
  ME_DETAIL_KPI_STORE = "@Navigation/ME_DETAIL_KPI_STORE",

  MN_FINANCE_PLANNING = "@Navigation/MN_FINANCE_PLANNING",
  MN_DETAIL_COMPLIANCE = "@Navigation/MN_DETAIL_COMPLIANCE",
  MN_ROI = "@Navigation/MN_ROI",
  MD_DETAIL_PRODUCT = "@Navigation/MN_DETAIL_PRODUCT",
  MD_DETAIL_STORE = "@Navigation/MN_DETAIL_STORE",

  SOS_DETAIL_PRODUCT = "@Navigation/SOS_DETAIL_PRODUCT",
  SOS_DETAIL_STORE = "@Navigation/SOS_DETAIL_STORE",

  PROMO_PRICE_STORE = "@Navigation/PROMO_PRICE_STORE",
  PROMO_PRICE_PRODUCT = "@Navigation/PROMO_PRICE_PRODUCT",
}

export enum INavMainMenu {
  MY_ONE_PAGE = "@Navigation/MY_ONE_PAGE",
  MY_EXECUTION = "@Navigation/MY_EXECUTION",
  MY_NEGOCIATIONS = "@Navigation/MY_NEGOCIATIONS",
  MY_PERFECT_STORE = "@Navigation/MY_PERFECT_STORE",
  MY_FINANCIAL_PLANNING = "@Navigation/MY_FINANCIAL_PLANNING",
  PROMO = "@Navigation/PROMO",
  PROFILE_SETTINGS = "@Navigation/PROFILE_SETTINGS",
}

export enum ENavSubMenu {
  ONE_PAGE = "@Navigation/ONE_PAGE",
  ONE_PAGE_ROCKET = "@Navigation/ONE_PAGE_ROCKET",
  ONE_PAGE_PRODUCT = "@Navigation/ONE_PAGE_PRODUCT",
  ONE_PAGE_BANNER = "@Navigation/ONE_PAGE_BANNER",
  ME_DETAIL_STORE = "@Navigation/ME_DETAIL_STORE",
  ME_NPD = "@Navigation/ME_NPD",
  MN_CALENDAR = "@Navigation/MN_CALENDAR",
  MN_COMPLIANCE = "@Navigation/MN_COMPLIANCE",
  MN_FINANCE_PLANNING = "@Navigation/MN_FINANCE_PLANNING",
  MN_ROI = "@Navigation/MN_ROI",
  MD_BOOK = "@Navigation/MD_BOOK",
  MD_DAY = "@Navigation/MD_DAY",
  MD_EXTRACT_DATA = "@Navigation/MD_EXTRACT_DATA",
}

export interface IChangeMenu {
  type: menuTypes.CHANGE_MENU;
  payload: IMainMenu;
}

export interface IChangeTitleMenu {
  type: menuTypes.CHANGE_TITLE_MENU;
  payload: string;
}

export interface IChangeSubMenu {
  type: menuTypes.CHANGE_SUBMENU;
  payload: ENavSubMenu;
}

export interface IChangeFilters {
  type: menuTypes.CHANGE_FILTERS;
  payload: filterComponent[];
}

export interface IChangeMain {
  type: menuTypes.CHANGE_PAGE;
  payload: INavPage;
}

export interface IChangeDash {
  type: menuTypes.CHANGE_CONTENT;
  payload: INavContent;
}

export interface IMainMenu {
  index: INavMainMenu;
  name: string;
}

export interface filterComponent {
  name: string;
  readonly componentFilters: JSX.Element[];
}

export interface stateType {
  readonly mainPage: INavPage;
  readonly contentDash: INavContent;
  readonly menuItem: IMainMenu;
  readonly indexSubMenu: ENavSubMenu;
  readonly filters: filterComponent[];
}
