import React, { useCallback, useContext, useEffect } from "react";
import { Flex, Grid, Image, Stack, Text } from "@chakra-ui/react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Card from "../../../../components/Card";

// images
import calendar from "../../../../assets/MyFinancial/calendar.png";
// import editIcon from "../../../../assets/MyFinancial/edit.svg";
import pontaGondola from "../../../../assets/Laverage/PontaGondola.png";
import displayChao from "../../../../assets/Laverage/DisplayChao.png";
import TableFinancialPlanning, {
  IDataFinancialPlanning,
} from "../../../../components/TableFinancialPlanning";
import FiltersList from "../../../../components/SideFilterMenu/Resource/FiltersList";
import { GlobalProps } from "../../../../store";

// actions redux
import * as ANavigator from "../../../../store/Navigation/actions";
import * as AFilters from "../../../../store/SideFilters/actions";

// types redux
import { stateMarketShare } from "../../../../store/SideFilters/RequestFilters/types";
import { filterComponent } from "../../../../store/Navigation/types";
import {
  IStateFilters,
  TPromoFilter,
  IProductFilter,
  INITIAL_FILTERS,
} from "../../../../store/SideFilters/types";
import { ConfigContext } from "../../../../services/Context/ConfigContext";

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchToProps {
  DNavigator: {
    changeFilters(filters: filterComponent[]): void;
  };

  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
    changeCnpjStore(payload: string): void;
  };
}

type props = IStateProps & IDispatchToProps;

const ViewFinancialPlanning: React.FC<props> = ({
  DNavigator,
  SAllFilters,
  SFilters,
  DFilters,
}: props): JSX.Element => {
  const { config } = useContext(ConfigContext);

  const FinancPlanTableData: IDataFinancialPlanning[] = [
    {
      codAction: 3218,
      gondolaEnd: pontaGondola,
      mainTable: [
        {
          brand: {
            qtdFamily: 3,
            name: "Sym",
          },
          family: "Com abas",
          baseVolume: "8.500",
          ProjVolume: "12.500",
          investment: "R$ 49.950,00",
          investmentPercent: "33,3%",
          ROIProj: 1.7,
        },
        {
          family: "Sem abas",
          baseVolume: "1.500",
          ProjVolume: "3.500",
          investment: "R$ 49.950,00",
          investmentPercent: "33,3%",
          ROIProj: 0.3,
        },
        {
          family: "Noturno",
          baseVolume: "1.500",
          ProjVolume: "3.500",
          investment: "R$ 49.950,00",
          investmentPercent: "33,3%",
          ROIProj: 0.3,
        },
        {
          brand: {
            qtdFamily: 1,
            name: "Personal baby",
          },
          family: "Lenços umidecidos",
          baseVolume: "2.000",
          ProjVolume: "3.000",
          investment: "R$ 49.950,00",
          investmentPercent: "33,3%",
          ROIProj: -0.1,
        },
        {
          brand: {
            qtdFamily: 1,
            name: "Total",
          },
          family: "",
          baseVolume: "12.000",
          ProjVolume: "19.000",
          investment: "R$ 150.000,00",
          investmentPercent: "100,0%",
          ROIProj: 1,
        },
      ],
      complementTable: [
        {
          investment: "R$ 49.950,00",
          realValume: "12.500",
          realROI: 1.7,
        },
        {
          investment: "R$ 49.950,00",
          realValume: "3.500",
          realROI: 0.3,
        },
        {
          investment: "R$ 49.950,00",
          realValume: "3.500",
          realROI: -0.1,
        },
        {
          investment: "R$ 49.950,00",
          realValume: "4.500",
          realROI: 2.6,
        },
        {
          investment: "R$ 49.950,00",
          realValume: "8.300",
          realROI: 3.4,
        },
      ],
    },
    {
      codAction: 3219,
      gondolaEnd: displayChao,
      mainTable: [
        {
          brand: {
            qtdFamily: 2,
            name: "Personal baby",
          },
          family: "Fralda",
          baseVolume: "8.500",
          ProjVolume: "12.500",
          investment: "R$ 49.950,00",
          investmentPercent: "33,3%",
          ROIProj: 1.7,
        },
        {
          family: "Lenços umidecidos",
          baseVolume: "1.500",
          ProjVolume: "3.500",
          investment: "R$ 49.950,00",
          investmentPercent: "33,3%",
          ROIProj: 0.3,
        },
        {
          brand: {
            qtdFamily: 1,
            name: "Kiss",
          },
          family: "Lenços de papel",
          baseVolume: "2.000",
          ProjVolume: "3.000",
          investment: "R$ 49.950,00",
          investmentPercent: "33,3%",
          ROIProj: -0.1,
        },
        {
          brand: {
            qtdFamily: 1,
            name: "Total",
          },
          family: "",
          baseVolume: "12.000",
          ProjVolume: "19.000",
          investment: "R$ 150.000,00",
          investmentPercent: "100,0%",
          ROIProj: 1,
        },
      ],
      complementTable: [
        {
          investment: "R$ 49.950,00",
          realValume: "12.500",
          realROI: 1.7,
        },
        {
          investment: "R$ 49.950,00",
          realValume: "3.500",
          realROI: 0.3,
        },
        {
          investment: "R$ 49.950,00",
          realValume: "3.500",
          realROI: -0.1,
        },
        {
          investment: "R$ 49.950,00",
          realValume: "3.500",
          realROI: -0.1,
        },
      ],
    },
  ];

  const renderFilter = useCallback(() => {
    if (config.showSideFilters.myExecution)
      DNavigator.changeFilters(
        FiltersList(
          SAllFilters,
          SFilters,
          {
            ...INITIAL_FILTERS,
            typeExecution: false,
            priorityItens: false,
          },
          DFilters
        )
      );
  }, [SAllFilters, SFilters, DNavigator]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter]);

  return (
    <Card title="Planejamento dos Investimentos" bg="transparent">
      <Stack w="100%" h="100%">
        <Flex p={3} color="#7F7F7F" align="center" bg="white">
          <Image src={calendar} alt="" width={65} />
          <Stack align="center" ml="40px">
            <Text fontWeight="bold">Periodo:</Text>
            <Text fontSize="14px">Outubro 21</Text>
          </Stack>
          <Stack align="center" ml="55px">
            <Text fontWeight="bold">Total de Ações</Text>
            <Text fontSize="14px">2 Alavancas</Text>
          </Stack>
        </Flex>
        <Stack bg="white" w="100%" h="100%" alignItems="center" pt="20px">
          {FinancPlanTableData.map((itemFinancialPlan, index) => {
            return (
              <Grid
                justifyContent="center"
                templateColumns="1fr auto"
                align="center"
                border="1px solid #E7E6E6"
                w="100%"
                p="10px"
                mt="5px"
                mx="3px"
              >
                <TableFinancialPlanning
                  data={itemFinancialPlan}
                  alavancas={
                    index === 0 ? "PONTA DE GONDOLA" : "DISPLAY DE CHÃO"
                  }
                />
              </Grid>
            );
          })}
        </Stack>
      </Stack>
    </Card>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DFilters: bindActionCreators(AFilters, dispatch),
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewFinancialPlanning);
