import { Text, View } from "@react-pdf/renderer";
import React from "react";

interface IOwnProps {
  numberCard: string;
  w?: string;
  h?: string;
  ml?: string;
}

const CardEmpty: React.FC<IOwnProps> = ({
  numberCard,
  ml,
  w,
  h,
}: IOwnProps) => {
  return (
    <View
      style={{
        width: w || "138px",
        height: h || "240px",
        border: "1px solid #dbdbdb",
        marginTop: "1px",
        backgroundColor: "white",
        justifyContent: "center",
        textAlign: "center",
        marginLeft: ml,
      }}
    >
      <Text style={{ color: "#dbdbdb", fontWeight: "bold", fontSize: "14" }}>
        {`FOTO ${numberCard}`}
      </Text>
    </View>
  );
};

CardEmpty.defaultProps = {
  ml: "0px",
  w: undefined,
  h: undefined,
};

export default CardEmpty;
