import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useCallback, useContext, useEffect, useState } from "react";
import { Flex, Grid } from "@chakra-ui/react";

// Global Props
import { GlobalProps } from "../../../../../store";

// Types
import {
  IStateFilters,
  IProductFilter,
  TPromoFilter,
  IShowFilters,
  INITIAL_FILTERS,
} from "../../../../../store/SideFilters/types";
import { stateMarketShare } from "../../../../../store/SideFilters/RequestFilters/types";

// Actions
import * as AFilters from "../../../../../store/SideFilters/actions";
import * as ANavigation from "../../../../../store/Navigation/actions";

// Images
import logo from "../../../../../assets/Industria/logo.png";
import Store from "../../../../../assets/Menu/store.png";
import lavarage from "../../../../../assets/myExecution/iconLavarage.svg";
import eye from "../../../../../assets/myExecution/iconsEye.svg";
import Price from "../../../../../assets/myExecution/price.png";
import on from "../../../../../assets/myExecution/on.svg";
import compliance from "../../../../../assets/myExecution/compliance.svg";

// configuration platform
import CardSubMenu, {
  EPeriod,
  IHeadersCard,
} from "../../../../../components/CardSubMenu";
import { ESubContribuition } from "../../../../../components/TemporalFilter";
import { useRequestPresence } from "../../../../../services/Hooks/MyExecution/useRequestPresence";
import { useRequestExtraExposure } from "../../../../../services/Hooks/MyExecution/useRequestExtraExposure";
import { useRequestPrice } from "../../../../../services/Hooks/MyExecution/useRequestPrice";
import { filterComponent } from "../../../../../store/Navigation/types";
import { theme } from "../../../../../styles/theme";
import FiltersList from "../../../../../components/SideFilterMenu/Resource/FiltersList";
import { useRequestShareOfShelf } from "../../../../../services/Hooks/MyExecution/useRequestShareOfShelf";
import { ETypeLegend } from "../../../../../components/LegendIcon";
import CustomMainCards, {
  EExtraPoint,
  ESelectedFirstLine,
  EToggle,
  ISelectedCards,
} from "../../../../../components/MyExecution/CustomMainCards";
import { ConfigContext } from "../../../../../services/Context/ConfigContext";
import { ICardMyExecution } from "../../../../../services/Hooks/config/types";
import { useRequestComplianceExtraExposure } from "../../../../../services/Hooks/MyExecution/useRequestComplianceExtraExposure";
import ShareOfShelf from "../../../../../components/MyExecution/ShareOfShelf";
import PriceTable from "../../../../../components/MyExecution/Price";
import ExtraExposureTable from "../../../../../components/MyExecution/ExtraExposure";
import PerfectStoreTable from "../../../../../components/MyExecution/PerfectStore";
import PresenceTable from "../../../../../components/MyExecution/Presence";
import ComplianceExtraExposureTable from "../../../../../components/MyExecution/ComplianceExtraExposure";
import {
  filterBanner,
  filteRetailEnviroment,
} from "../../../../../components/SideFilterMenu/Resource";
// import SeeDetailButton from "../../../../../components/SeeDetailButton";

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
    changeCnpjStore(payload: string): void;
  };

  sideFilter: {
    changeFilters(filters: filterComponent[]): void;
  };
}

type props = IStateProps & IDispatchProps;

const NPD = ({
  SAllFilters,
  SFilters,
  DFilters,
  sideFilter,
}: props): JSX.Element => {
  const { config } = useContext(ConfigContext);

  const [selections, setSelections] = useState<ISelectedCards>({
    selectPeriod: EPeriod.MONTHLY,
    selectedCard: ESelectedFirstLine.PRESENCE,
    selectedPresence: EToggle.PRESENCE,
    selectedExtraPoint: EExtraPoint.BRAND,
    selectedTemporalFilter: ESubContribuition.MAT,
  });

  const handleSelectedPeriod = (periodSelected: EPeriod) => {
    setSelections({ ...selections, selectPeriod: periodSelected });
  };

  // request datas api

  Promise.all([
    useRequestPresence(selections.selectPeriod, SFilters),
    useRequestShareOfShelf(selections.selectPeriod, SFilters),
    useRequestExtraExposure(selections.selectPeriod, SFilters),
    useRequestComplianceExtraExposure(selections.selectPeriod, SFilters),
    useRequestPrice(selections.selectPeriod, SFilters)
  ]).then(response => {
    console.log(response);
  }).catch((err) => console.log(err));



  const presenceData = useRequestPresence(selections.selectPeriod, SFilters);
  const shareOfShelfData = useRequestShareOfShelf(
    selections.selectPeriod,
    SFilters
  );
  const extraExposureData = useRequestExtraExposure(
    selections.selectPeriod,
    SFilters
  );
  const complianceExtraExposure = useRequestComplianceExtraExposure(
    selections.selectPeriod,
    SFilters
  );
  const price = useRequestPrice(selections.selectPeriod, SFilters);

  const renderHeaderCards = (): IHeadersCard[] => {
    return Object.values(config.modules.myExecution.cards)
      .filter((card: ICardMyExecution) => card.activate)
      .map((headerActive) => {
        const newHeader: IHeadersCard = {
          title: headerActive.label,
          complements: headerActive.subLabel,
        };

        return newHeader;
      });
  };

  const renderFilter = useCallback(() => {
    let showFilters: IShowFilters = {
      ...INITIAL_FILTERS,
      businessUnitId: true,
      category: true,
      subCategory: true,
      subChannel: true,
      banner: true,
      region: true,
      uf: true,
      isPromo: true,
      commercialStructureDirector: true,
      commercialStructureManager: true,
      commercialStructurCoordinator: true,
      commercialStructureSales: true,
      storeCNPJ: true,
      gpdv: true,
      retailEnviroment: true,
      // merchandising: true,
    };

    if (
      selections.selectedCard === ESelectedFirstLine.YELLOW_PHARMA ||
      selections.selectedCard === ESelectedFirstLine.ON
    ) {
      showFilters = {
        ...INITIAL_FILTERS,
        subChannel: true,
        banner: true,
        region: true,
        uf: true,
      };
    }

    if (selections.selectedCard === ESelectedFirstLine.COMPLIANCE_EXTRA_POINT) {
      showFilters = {
        ...INITIAL_FILTERS,
        category: true,
        businessUnitId: true,
        subCategory: true,
        subChannel: true,
        banner: true,
        region: true,
        uf: true,
        isPromo: true,
        commercialStructureDirector: true,
        commercialStructureManager: true,
        commercialStructurCoordinator: true,
        commercialStructureSales: true,
        storeCNPJ: true,
        gpdv: true,
        retailEnviroment: true,
        // merchandising: true,
      };
    }

    if (config.showSideFilters.myExecution)
      sideFilter.changeFilters(
        FiltersList(SAllFilters, SFilters, showFilters, DFilters)
      );
  }, [SAllFilters, SFilters, sideFilter, selections.selectedCard]);

  useEffect(() => {
    renderFilter();
  }, [selections.selectedCard, renderFilter]);

  // useEffect(() => {
  //   DFilters.changeFilterIsPromo({
  //     ...SFilters.promoFilter,
  //     banner: {
  //       selectedFilterId: "",
  //       selectedFilterName: "",
  //       listFilters: [],
  //     },
  //     subChannel: {
  //       selectedFilterId: "",
  //       selectedFilterName: "",
  //       listFilters: [],
  //     },
  //     retailEnviroment: {
  //       selectedFilterId: "",
  //       selectedFilterName: "",
  //       listFilters: [],
  //     },
  //   });
  // }, []);

  return (
    <Grid templateRows="repeat(4, auto)">
      <Flex
        mb="5px"
        css={`
          &::-webkit-scrollbar {
            background-color: darkgray;
            border-radius: 20px;
            width: 12px;
            height: 12px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background-color: ${theme.scrollColor};
          }
        `}
        overflowY="hidden"
      >
        <CardSubMenu
          minWHeader="530px"
          headers={renderHeaderCards()}
          subItens={[
            {
              id: EPeriod.MONTHLY,
              label: "Mensal",
            },
            {
              id: EPeriod.WEEKLY,
              label: "Semanal",
            },
          ]}
          selectedPeriod={handleSelectedPeriod}
        >
          <>
            {config.modules.myExecution.cards.presence?.activate && (
              <CustomMainCards
                selectPeriod={selections.selectPeriod}
                icon={Store}
                data={presenceData.data}
                legends={[
                  { value: "Realizado", type: ETypeLegend.bar },
                  { type: ETypeLegend.period, value: "Var. vs AA %" },
                ]}
                stateSelections={selections}
                value={ESelectedFirstLine.PRESENCE}
                setSelections={setSelections}
                isLoading={presenceData.isLoading}
                hasMargin
                minWidth="max-content"
              />
            )}
            {config.modules.myExecution.cards.shareOfShelf?.activate && (
              <CustomMainCards
                selectPeriod={selections.selectPeriod}
                icon={lavarage}
                data={shareOfShelfData.data}
                legends={[
                  { value: "Realizado", type: ETypeLegend.bar },
                  { type: ETypeLegend.period, value: "Var. vs AA %" },
                ]}
                stateSelections={selections}
                value={ESelectedFirstLine.GONDOLA}
                setSelections={setSelections}
                isLoading={shareOfShelfData.isLoading}
                hasMargin
                minWidth="max-content"
              />
            )}
            {config.modules.myExecution.cards.extraExposure?.activate && (
              <CustomMainCards
                selectPeriod={selections.selectPeriod}
                icon={eye}
                data={extraExposureData.data}
                legends={[
                  { value: "Realizado", type: ETypeLegend.bar },
                  { type: ETypeLegend.period, value: "Var. vs AA %" },
                ]}
                stateSelections={selections}
                setSelections={setSelections}
                value={ESelectedFirstLine.EXTRA_POINT}
                isLoading={extraExposureData.isLoading}
                hasMargin
                minWidth="max-content"
              />
            )}
            {config.modules.myExecution.cards.complianceExtraExposure
              ?.activate && (
              <CustomMainCards
                selectPeriod={selections.selectPeriod}
                icon={compliance}
                data={complianceExtraExposure.data}
                legends={[
                  { value: "Realizado", type: ETypeLegend.bar },
                  { type: ETypeLegend.period, value: "Var. vs AA %" },
                ]}
                stateSelections={selections}
                setSelections={setSelections}
                value={ESelectedFirstLine.COMPLIANCE_EXTRA_POINT}
                isLoading={complianceExtraExposure.isLoading}
                hasMargin
                minWidth="max-content"
              />
            )}
            {config.modules.myExecution.cards.price?.activate && (
              <CustomMainCards
                selectPeriod={selections.selectPeriod}
                icon={Price}
                data={price.data}
                legends={[
                  { value: "Realizado", type: ETypeLegend.bar },
                  { type: ETypeLegend.period, value: "Var. vs AA %" },
                ]}
                stateSelections={selections}
                setSelections={setSelections}
                value={ESelectedFirstLine.PRICE}
                isLoading={price.isLoading}
                hasMargin
                minWidth="max-content"
              />
            )}
            {config.modules.myExecution.cards.perfectStore?.activate && (
              <CustomMainCards
                selectPeriod={selections.selectPeriod}
                icon={on}
                data={price.data}
                legends={[
                  { value: "Realizado", type: ETypeLegend.bar },
                  { value: "Var. vs AA %", type: ETypeLegend.period },
                ]}
                stateSelections={selections}
                setSelections={setSelections}
                value={ESelectedFirstLine.ON}
                isLoading={price.isLoading}
                hasMargin
                minWidth="max-content"
              />
            )}
          </>
        </CardSubMenu>
      </Flex>
      {(selections.selectedCard === ESelectedFirstLine.PRESENCE && (
        <PresenceTable SFilters={SFilters} period={selections.selectPeriod} />
      )) ||
        (selections.selectedCard === ESelectedFirstLine.GONDOLA && (
          <ShareOfShelf SFilters={SFilters} period={selections.selectPeriod} />
        )) ||
        (selections.selectedCard === ESelectedFirstLine.EXTRA_POINT && (
          <ExtraExposureTable
            SFilters={SFilters}
            period={selections.selectPeriod}
          />
        )) ||
        (selections.selectedCard === ESelectedFirstLine.PRICE && (
          <PriceTable SFilters={SFilters} period={selections.selectPeriod} />
        )) ||
        (selections.selectedCard === ESelectedFirstLine.ON && (
          <PerfectStoreTable
            SFilters={SFilters}
            period={selections.selectPeriod}
          />
        ))}
      {selections.selectedCard ===
        ESelectedFirstLine.COMPLIANCE_EXTRA_POINT && (
        <ComplianceExtraExposureTable SFilters={SFilters} />
      )}
    </Grid>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DFilters: bindActionCreators(AFilters, dispatch),
    sideFilter: bindActionCreators(ANavigation, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NPD);
