import { put, call, CallEffect, PutEffect } from "redux-saga/effects";
import api from "../../../services/api";
import {
  stateMarketShare,
  ILoadError,
  ILoadSuccess,
  TFilterExecutionType,
} from "./types";
import { loadSuccess, loadFailure } from "./actions";

export function* load(): Generator<
  CallEffect<unknown> | PutEffect<ILoadSuccess> | PutEffect<ILoadError>,
  void,
  stateMarketShare
> {
  try {
    const response: stateMarketShare = yield call(api.get, "filters/");
    const filterEvidence =
      response.data.myExecution.subviewOSA.secondary.execution_leverage
        .filter((leverage) => leverage.label.toLowerCase() !== "planograma")
        .map((leverage): TFilterExecutionType => {
          const idTypeExecutionSearch =
            response.data.myExecution.subviewOSA.main.executions_type.filter(
              (type) => type.label.includes("Pesquisado")
            )[0];

          return {
            ...leverage,
            executionTypeId: idTypeExecutionSearch.id,
          };
        });
    response.data.myExecution.subviewOSA.secondary.execution_leverage.push(
      ...filterEvidence
    );

    yield put(loadSuccess(response));
  } catch (e) {
    yield put(loadFailure());
  }
}
