import { Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useBottomTableStoreAbsolute } from "../../../../services/Hooks/MyPerfectStore/useBottomTableStoreAbsolute";
import { INavContent } from "../../../../store/Navigation/types";
import { IStateFilters } from "../../../../store/SideFilters/types";
import Card from "../../../Card";
import SeeDetailButton from "../../../SeeDetailButton";
import TableWithGraphic from "../../../TableWithGraphic";
import { PSAbsolutBottomTable } from "../../data";

interface IOwnProps {
  filters: IStateFilters;
}

const BottomTable: React.FC<IOwnProps> = ({
  filters,
}: IOwnProps): JSX.Element => {
  const { data } = useBottomTableStoreAbsolute(filters);

  return (
    <>
      <Card title="Ranking LOJAS por Pontuação" bg="tranparent">
        <>
          <Stack w="100%" bg="white">
            <Flex
              justifyContent="space-between"
              px="5px"
              align="center"
              // mt="45px"
            >
              <Text
                color="black"
                p="15px 10px 5px 10px"
                fontSize="16px"
                fontWeight="bold"
              >
                TOP 10 Lojas mais bem PONTUADAS
              </Text>
            </Flex>
            <TableWithGraphic isLoading={false} tableData={data?.top} />
          </Stack>
          <Stack w="100%" bg="white" ml="5px">
            <Flex
              justifyContent="space-between"
              px="5px"
              align="center"
              // mt="45px"
            >
              <Text
                color="#c00000"
                p="15px 10px 5px 10px"
                fontSize="16px"
                fontWeight="bold"
              >
                TOP 10 Lojas OFENSORAS
              </Text>
              {/* <SeeDetailButton
                label="Ver detalhe LOJA"
                goPage={INavContent.ME_DETAIL_STORE}
              /> */}
            </Flex>
            <TableWithGraphic isLoading={false} tableData={data?.offenders} />
          </Stack>
        </>
      </Card>
    </>
  );
};

export default BottomTable;
