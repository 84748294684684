import axios from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import { urlRequestApi } from "../../api";

const baseUrl = "https://api.integration.tradexbip.com/";

export interface IKPIExtract {
  extraExposure: boolean;
  shareOfShelf: boolean;
  compliance: boolean;
  rupture: boolean;
  perfectStore: boolean;
}

interface IParamsExtractData {
  industryId: string;
  filters: IStateFilters;
  kpisSelected: IKPIExtract;
}

interface IReturnExtractData {
  urlShareOfShelf: string;
  urlRupture: string;
  urlExtraExposure: string;
  urlCompliance: string;
  urlPerfectStore: string;
}

interface IFormatReturn {
  file: string;
  message: string;
  status: string;
}

const requestExportData = async ({
  industryId,
  filters,
  kpisSelected,
}: IParamsExtractData): Promise<IReturnExtractData> => {
  const urlExtractData: IReturnExtractData = {
    urlCompliance: "",
    urlExtraExposure: "",
    urlRupture: "",
    urlShareOfShelf: "",
    urlPerfectStore: "",
  };

  const parameters = {
    industry_id: industryId || null,
    initial_execution_date: filters.promoFilter.dateInitial || null,
    end_execution_date: filters.promoFilter.dateFinal || null,
    businessUnit: filters.promoFilter.businessUnitId.selectedFilterName || null,
    category: filters.promoFilter.category.selectedFilterName || null,
    subCategory: filters.promoFilter.brand.selectedFilterName || null,
    subChannel: filters.promoFilter.subChannel.selectedFilterName || null,
    banner: filters.promoFilter.banner.selectedFilterName || null,
    region: filters.promoFilter.region.selectedFilterName || null,
    uf: filters.promoFilter.uf.selectedFilterName || null,
    executionType: filters.promoFilter.executionType.selectedFilterName || null,
    executionLeverage:
      filters.promoFilter.executionLeverage.selectedFilterName || null,
    cnpj: filters.promoFilter.storeCNPJ || null,
    gpdv_id: filters.promoFilter.gpdv.selectedFilterId || null,
    retailEnviroment:
      filters.promoFilter.retailEnviroment.selectedFilterId || null,
  };

  if (kpisSelected.extraExposure) {
    const extraExposure = await axios.get<IFormatReturn>(
      `${baseUrl}export_extra_exposure/`,
      {
        params: parameters,
      }
    );

    urlExtractData.urlExtraExposure = extraExposure.data.file;
  }

  if (kpisSelected.shareOfShelf) {
    const shareOfShelf = await axios.get<IFormatReturn>(
      `${baseUrl}export_share_of_shelf/`,
      {
        params: parameters,
      }
    );

    urlExtractData.urlShareOfShelf = shareOfShelf.data.file;
  }

  if (kpisSelected.compliance) {
    const compliance = await axios.get<IFormatReturn>(
      `${baseUrl}export_compliance/`,
      {
        params: parameters,
      }
    );

    urlExtractData.urlCompliance = compliance.data.file;
  }

  if (kpisSelected.rupture) {
    const rupture = await axios.get<IFormatReturn>(
      `${baseUrl}export_rupture/`,
      {
        params: parameters,
      }
    );

    urlExtractData.urlRupture = rupture.data.file;
  }

  if (kpisSelected.perfectStore) {
    const perfectStore = await axios.get<IFormatReturn>(
      `${urlRequestApi}perfect_store_santher/`,
      {
        params: {
          start_date: filters.promoFilter.dateInitial || null,
          end_date: filters.promoFilter.dateFinal || null,
          businessUnit:
            filters.promoFilter.businessUnitId.selectedFilterName || null,
          category: filters.promoFilter.category.selectedFilterName || null,
          subCategory: filters.promoFilter.brand.selectedFilterName || null,
          subChannel: filters.promoFilter.subChannel.selectedFilterName || null,
          banner: filters.promoFilter.banner.selectedFilterName || null,
          region: filters.promoFilter.region.selectedFilterName || null,
          uf: filters.promoFilter.uf.selectedFilterName || null,
          executionType:
            filters.promoFilter.executionType.selectedFilterName || null,
          executionLeverage:
            filters.promoFilter.executionLeverage.selectedFilterName || null,
          cnpj: filters.promoFilter.storeCNPJ || null,
          gpdv_id: filters.promoFilter.gpdv.selectedFilterId || null,
        },
      }
    );

    urlExtractData.urlPerfectStore = perfectStore.data.file;
  }

  return urlExtractData;
};

export const useExportData = (
  data: IParamsExtractData
): UseQueryResult<IReturnExtractData, unknown> => {
  return useQuery(["exportData"], () => requestExportData(data), {
    refetchOnWindowFocus: false,
    enabled: false,
    retry: 2,
  });
};
