/* eslint-disable camelcase */
import { useQuery, UseQueryResult } from "react-query";
import api from "../../api";

export interface ILeverageData {
  id: string;
  name: string;
  imageTemplate: string;
  length: string;
  width: string;
  number_of_side: number;
  shelf_quantity: number;
  is_active: boolean;
  type_execution_leverage: string;
}

const requestLeverage = async (): Promise<ILeverageData[]> => {
  const response = await api.get<{ executionsLeverages: ILeverageData[] }>(
    "executions_leverages/"
  );
  return response.data.executionsLeverages;
};

export const useRequestLeverage = (): UseQueryResult<
  ILeverageData[],
  unknown
> => {
  return useQuery(["requestLeverage"], requestLeverage, {
    refetchOnWindowFocus: false,
    enabled: false,
    retry: 2,
  });
};
