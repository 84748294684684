import { Box, Flex, Grid, Image, Stack, Text } from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

// Images
import lupa from "../../../../assets/OnePage/magnifying_glass_black.png";

// graphics
import MarketSharePeriodLtyYtd from "../../../../components/Graphics/MarketSharePeriodLtyYtd/MarketSharePeriodLtyYtd";
import MarketSharePeriodBarLtyYtd from "../../../../components/Graphics/MarketSharePeriodBarLtyYtd/MarketSharePeriodBarLtyYtd";

// Action redux
import * as ANavigator from "../../../../store/Navigation/actions";

// components
import Card from "../../../../components/Card";
import { GlobalProps } from "../../../../store";
import { formatName } from "../../../../components/Graphics/Resource/GraphicsFunctions";
import CardSubMenu, { EPeriod } from "../../../../components/CardSubMenu";
import TableComplements, {
  mockBrands,
  extraPoint,
  priceMock,
} from "../../../../components/TableComplements";
import { theme } from "../../../../styles/theme";
import FiltersList from "../../../../components/SideFilterMenu/Resource/FiltersList";
import { stateMarketShare } from "../../../../store/SideFilters/RequestFilters/types";
import {
  INITIAL_FILTERS,
  IStateFilters,
} from "../../../../store/SideFilters/types";
import {
  filterComponent,
  INavContent,
} from "../../../../store/Navigation/types";
import LegendIcon, { ETypeLegend } from "../../../../components/LegendIcon";
import { ConfigContext } from "../../../../services/Context/ConfigContext";

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchToProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeFilters(filters: filterComponent[]): void;
  };
}

enum ECompliance {
  COMPLIANCE_EVOLUTION = "Evolução",
  COMPLIANCE_POINT_EXTRA = "Pontos Extras",
  COMPLIANCE_PRICE = "Preços",
}

enum EPresence {
  BRAND = 0,
  REGION = 1,
  SUB_CHANNEL = 2,
  BANNERS = 3,
}

interface ISelectedCards {
  selectPeriod: EPeriod;
  selectedCard: ECompliance;
  selectedPresence: EPresence;
}

type props = IStateProps & IDispatchToProps;

const ContentCompliance: React.FC<props> = ({
  DNavigator,
  SAllFilters,
  SFilters,
}: props) => {
  const [selections, setSelections] = useState<ISelectedCards>({
    selectPeriod: EPeriod.MONTHLY,
    selectedCard: ECompliance.COMPLIANCE_EVOLUTION,
    selectedPresence: EPresence.BRAND,
  });

  const { config } = useContext(ConfigContext);

  const handleSelectedPeriod = (periodSelected: EPeriod) => {
    setSelections({ ...selections, selectPeriod: periodSelected });
  };

  const dataMock = {
    brand: {
      label: ["58,0%", "62,0%", "38,0%", "94,0%"],
      x: ["Termometro", "Cardio", "Eq.Med-Hosp", "Respiratoria", ""],
      y: [58, 62, 38, 94, 0],
    },
    region: {
      label: ["58,0%", "62,0%", "38,0%", "94,0%"],
      x: ["Centro Oeste", "Nordeste", "Norte", "Sudeste", "Sul"],
      y: [58, 62, 38, 94, 0],
    },
    subChannel: {
      label: ["58,0%", "62,0%", "38,0%", "94,0%"],
      x: ["G.Redes", "M.Redes", "P.Redes", "Assoc.", "Indeps."],
      y: [58, 62, 38, 94, 0],
    },
    banners: {
      label: [
        "57,8%",
        "43,4%",
        "50,5%",
        "65,5%",
        "52,6%",
        "19,3%",
        "75,3%",
        "53,7%",
        "61,8%",
        "68,8%",
      ],
      x: [
        "DSP",
        "Drogaria Venancio",
        "Drogasil",
        "Indiana",
        "Pague Menos",
        "Carrefour",
        "Farmacia Lider",
        "Raia",
        "Ultra Popular",
        "Coop",
      ],
      y: [57.8, 46.4, 50.5, 65.5, 52.6, 19.3, 75.3, 53.7, 61.8, 68.8],
    },
  };

  const headerCards = [
    "Presença % Marcas",
    "Presença % Regionais",
    "Presença % SubCanais",
    "Presença % Top 10 Bandeiras",
  ];

  const renderFilter = useCallback(() => {
    if (config.showSideFilters.myExecution)
      DNavigator.changeFilters(
        FiltersList(SAllFilters, SFilters, {
          ...INITIAL_FILTERS,
          segment: false,
          executionLeverage: false,
          priorityItens: false,
        })
      );
  }, [SAllFilters, SFilters, DNavigator]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter]);

  return (
    <Grid templateRows="repeat(3, auto)" gridRowGap="5px">
      <Flex>
        <CardSubMenu
          headers={[
            {
              title: "Evolução do Compliance % ",
              complements: "(semanal)",
            },
            {
              title: "Compliance % Pontos Extras",
              complements: "(última semana)",
            },
            {
              title: "Compliance % Preços",
              complements: "(última semana)",
            },
          ]}
          subItens={[
            {
              id: EPeriod.MONTHLY,
              label: "Mensal",
            },
            {
              id: EPeriod.WEEKLY,
              label: "Semanal",
            },
          ]}
          selectedPeriod={handleSelectedPeriod}
        >
          <>
            <Stack
              bg="white"
              w="100%"
              cursor="pointer"
              position="relative"
              onClick={() =>
                setSelections({
                  ...selections,
                  selectedCard: ECompliance.COMPLIANCE_EVOLUTION,
                })
              }
              _before={
                (selections.selectedCard ===
                  ECompliance.COMPLIANCE_EVOLUTION && {
                  content: `""`,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderWidth: "4px",
                  borderColor: "selectedColor",
                  zIndex: "5",
                }) ||
                {}
              }
              _after={
                (selections.selectedCard ===
                  ECompliance.COMPLIANCE_EVOLUTION && {
                  content: '""',
                  w: 0,
                  h: 0,
                  borderTopWidth: "15px",
                  borderTopColor: "selectedColor",
                  borderLeft: "solid 50px transparent",
                  borderRight: "solid 50px transparent",
                  position: "absolute",
                  left: "calc(50% - 48px)",
                  bottom: "-14px",
                  zIndex: 5,
                }) ||
                {}
              }
            >
              <Flex p="0 5px" justifyContent="center">
                <Stack justifyContent="center" mr="20px">
                  <MarketSharePeriodLtyYtd
                    lineWidth={false}
                    dataGraphic={{
                      label: ["58,2%"],
                      y: [58.2],
                    }}
                    ml={0}
                    mr={0}
                  />
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={{
                      label: ["1.752"],
                      x: ["MTD"],
                      y: [1752],
                    }}
                  />
                </Stack>
                <Stack alignItems="flex-end" justifyContent="space-between">
                  <MarketSharePeriodLtyYtd
                    dataGraphic={{
                      label: ["30.9%", "32.5%", "32.9%", "32.6%", "14.1%"],
                      y: [30.9, 32.5, 32.9, 32.6, 14.1],
                    }}
                    ml={10}
                    mr={5}
                  />
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={{
                      label: ["929", "979", "989", "980", "424"],
                      x: ["S9", "S10", "S11", "S12", "S13"],
                      y: [929, 979, 989, 980, 424],
                    }}
                    bgColor={theme.graphics.light}
                  />
                </Stack>
              </Flex>
              <Flex
                position="relative"
                justify="center"
                align="center"
                color="black"
                fontSize="12px"
              >
                <LegendIcon typeLegend={ETypeLegend.bar}>
                  Compliance %
                </LegendIcon>
                <LegendIcon ml="10px" typeLegend={ETypeLegend.period}>
                  Ações Negociadas
                </LegendIcon>
              </Flex>
            </Stack>
            <Stack
              bg="white"
              w="100%"
              ml="5px"
              position="relative"
              cursor="pointer"
              onClick={() =>
                setSelections({
                  ...selections,
                  selectedCard: ECompliance.COMPLIANCE_POINT_EXTRA,
                })
              }
              _before={
                (selections.selectedCard ===
                  ECompliance.COMPLIANCE_POINT_EXTRA && {
                  content: `""`,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderWidth: "4px",
                  borderColor: "selectedColor",
                  zIndex: "5",
                }) ||
                {}
              }
              _after={
                (selections.selectedCard ===
                  ECompliance.COMPLIANCE_POINT_EXTRA && {
                  content: '""',
                  w: 0,
                  h: 0,
                  borderTopWidth: "15px",
                  borderTopColor: "selectedColor",
                  borderLeft: "solid 50px transparent",
                  borderRight: "solid 50px transparent",
                  position: "absolute",
                  left: "calc(50% - 48px)",
                  bottom: "-14px",
                  zIndex: 5,
                }) ||
                {}
              }
            >
              <Flex Flex p="0 5px" justifyContent="center">
                <Stack justifyContent="center" mr="20px">
                  <MarketSharePeriodLtyYtd
                    lineWidth={false}
                    dataGraphic={{
                      label: ["58,2%"],
                      y: [58.2],
                    }}
                    ml={0}
                    mr={0}
                  />
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={{
                      label: ["1.752"],
                      x: ["MTD"],
                      y: [1752],
                    }}
                  />
                </Stack>
                <Stack alignItems="flex-end" justifyContent="space-between">
                  <MarketSharePeriodLtyYtd
                    dataGraphic={{
                      label: ["30.9%", "32.5%", "32.9%", "32.6%", "14.1%"],
                      y: [30.9, 32.5, 32.9, 32.6, 14.1],
                    }}
                    ml={10}
                    mr={5}
                  />
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={{
                      label: ["929", "979", "989", "980", "424"],
                      x: ["S9", "S10", "S11", "S12", "S13"],
                      y: [929, 979, 989, 980, 424],
                    }}
                    bgColor={theme.graphics.light}
                  />
                </Stack>
              </Flex>
              <Flex
                position="relative"
                justify="center"
                align="center"
                color="black"
                fontSize="12px"
              >
                <LegendIcon typeLegend={ETypeLegend.bar}>
                  Compliance %
                </LegendIcon>
                <LegendIcon ml="10px" typeLegend={ETypeLegend.period}>
                  Pontos Extras Negociados
                </LegendIcon>
              </Flex>
            </Stack>
            <Stack
              bg="white"
              w="100%"
              ml="5px"
              position="relative"
              cursor="pointer"
              onClick={() =>
                setSelections({
                  ...selections,
                  selectedCard: ECompliance.COMPLIANCE_PRICE,
                })
              }
              _before={
                (selections.selectedCard === ECompliance.COMPLIANCE_PRICE && {
                  content: `""`,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderWidth: "4px",
                  borderColor: "selectedColor",
                  zIndex: "5",
                }) ||
                {}
              }
              _after={
                (selections.selectedCard === ECompliance.COMPLIANCE_PRICE && {
                  content: '""',
                  w: 0,
                  h: 0,
                  borderTopWidth: "15px",
                  borderTopColor: "selectedColor",
                  borderLeft: "solid 50px transparent",
                  borderRight: "solid 50px transparent",
                  position: "absolute",
                  left: "calc(50% - 48px)",
                  bottom: "-14px",
                  zIndex: 5,
                }) ||
                {}
              }
            >
              <Flex Flex p="0 5px" justifyContent="center">
                <Stack justifyContent="center" mr="20px">
                  <MarketSharePeriodLtyYtd
                    lineWidth={false}
                    dataGraphic={{
                      label: ["58,2%"],
                      y: [58.2],
                    }}
                    ml={0}
                    mr={0}
                  />
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={{
                      label: ["1.752"],
                      x: ["MTD"],
                      y: [1752],
                    }}
                  />
                </Stack>
                <Stack alignItems="flex-end" justifyContent="space-between">
                  <MarketSharePeriodLtyYtd
                    dataGraphic={{
                      label: ["30.9%", "32.5%", "32.9%", "32.6%", "14.1%"],
                      y: [30.9, 32.5, 32.9, 32.6, 14.1],
                    }}
                    ml={10}
                    mr={5}
                  />
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={{
                      label: ["929", "979", "989", "980", "424"],
                      x: ["S9", "S10", "S11", "S12", "S13"],
                      y: [929, 979, 989, 980, 424],
                    }}
                    bgColor={theme.graphics.light}
                  />
                </Stack>
              </Flex>
              <Flex
                position="relative"
                justify="center"
                align="center"
                color="black"
                fontSize="12px"
              >
                <LegendIcon typeLegend={ETypeLegend.bar}>
                  Compliance %
                </LegendIcon>
                <LegendIcon ml="10px" typeLegend={ETypeLegend.period}>
                  Rebaixadas/Lojas Negociadas
                </LegendIcon>
              </Flex>
            </Stack>
          </>
        </CardSubMenu>
      </Flex>
      <Flex>
        {{ ...dataMock, titles: headerCards }.titles.map((card, index) => {
          return (
            <Card
              mr="5px"
              title={card}
              subTitle="(Periodo: ------)"
              w={(index === 3 && "40%") || "20%"}
              overflow="none"
            >
              <Stack
                w="100%"
                p="5px"
                position="relative"
                align="flex-end"
                onClick={() =>
                  setSelections({ ...selections, selectedPresence: index })
                }
                _before={
                  (selections.selectedPresence === index && {
                    content: `""`,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderWidth: "4px",
                    borderColor: "selectedColor",
                    zIndex: "5",
                    top: 0,
                    right: 0,
                  }) ||
                  {}
                }
                _after={
                  (index === selections.selectedPresence && {
                    content: '""',
                    w: 0,
                    h: 0,
                    borderTopWidth: "15px",
                    borderTopColor: "selectedColor",
                    borderLeft: "solid 50px transparent",
                    borderRight: "solid 50px transparent",
                    position: "absolute",
                    left: "calc(50% - 48px)",
                    bottom: "-14px",
                    zIndex: 5,
                  }) ||
                  {}
                }
              >
                <Flex
                  cursor="pointer"
                  w="fit-content"
                  bg="#E7E6E6"
                  p="3px 45px 3px 10px"
                  position="relative"
                >
                  <Text fontStyle="italic" color="black">
                    {selections.selectedPresence === index ? "Fechar" : "Ver"}{" "}
                    Evolutiva
                  </Text>
                  <Image
                    position="absolute"
                    top="-7px"
                    right="0"
                    w="40px"
                    src={lupa}
                    zIndex={20}
                  />
                </Flex>
                <Box w="100%">
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={
                      (index === 0 && formatName(dataMock.brand)) ||
                      (index === 1 && formatName(dataMock.region)) ||
                      (index === 2 && formatName(dataMock.subChannel)) ||
                      formatName(dataMock.banners)
                    }
                    bgColor={
                      index === selections.selectedPresence
                        ? theme.graphics.dark
                        : theme.graphics.light
                    }
                    b={39}
                    autosize
                  />
                </Box>
              </Stack>
            </Card>
          );
        })}
      </Flex>
      {(selections.selectedCard === ECompliance.COMPLIANCE_EVOLUTION && (
        <TableComplements
          mainFilter={[]}
          secundaryFilter={[]}
          titleCard="Share de Gondola %"
          subTitleCard="(Mensal)"
          subTitle="Detalhe ALAVANCAS / MARCAS"
          data={mockBrands}
          goDetail={{
            detailPage: INavContent.MN_DETAIL_COMPLIANCE,
            label: "Detalhe Compliance",
          }}
        />
      )) ||
        (selections.selectedCard === ECompliance.COMPLIANCE_POINT_EXTRA && (
          <TableComplements
            mainFilter={[]}
            secundaryFilter={[]}
            titleCard="Share de Gondola %"
            subTitleCard="(Mensal)"
            subTitle="Detalhe ALAVANCAS / MARCAS"
            data={extraPoint}
            goDetail={{
              detailPage: INavContent.MN_DETAIL_COMPLIANCE,
              label: "Detalhe Compliance",
            }}
          />
        )) ||
        (selections.selectedCard === ECompliance.COMPLIANCE_PRICE && (
          <TableComplements
            mainFilter={[]}
            secundaryFilter={[]}
            titleCard="Share de Gondola %"
            subTitleCard="(Mensal)"
            subTitle="Detalhe ALAVANCAS / MARCAS"
            data={priceMock}
            minWidthImage="60%"
            goDetail={{
              detailPage: INavContent.MN_DETAIL_COMPLIANCE,
              label: "Detalhe Compliance",
            }}
          />
        ))}
    </Grid>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentCompliance);
