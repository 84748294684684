import React, { useState } from "react";
import {
  IAbsoluteData,
  IDetailPerfectStore,
  INITIAL_DETAIL_PERFECT_STORE,
} from "../../../services/Hooks/MyPerfectStore/useStoreAbsolute";
import { IStateFilters } from "../../../store/SideFilters/types";
import SubTableComplements from "../../subTableComplements";
import BottomTable from "./RankingStoreByPontuation";
import DetailPerfectStore from "./DetailPerfectStore";

interface ITypeDetailPerfectStore {
  subChannel: IDetailPerfectStore;
  banner: IDetailPerfectStore;
  region: IDetailPerfectStore;
}
interface IOwnProps {
  storeAbsolute: IAbsoluteData;
  period: string;
  detailPerfectStore: ITypeDetailPerfectStore;
  SFilters: IStateFilters;
}

const PerfectStoreAbsolut: React.FC<IOwnProps> = ({
  storeAbsolute,
  detailPerfectStore,
  period,
  SFilters,
}: IOwnProps): JSX.Element => {
  const [selectedSubCard, setSelectedSubCard] = useState<string>("");
  const graphicDetailStore =
    (selectedSubCard === "sub_channel" && detailPerfectStore.subChannel) ||
    (selectedSubCard === "region" && detailPerfectStore.region) ||
    (selectedSubCard === "banner" && detailPerfectStore.banner) ||
    INITIAL_DETAIL_PERFECT_STORE;
  return (
    <>
      <SubTableComplements
        isLoading={false}
        data={{
          presenceBanner: storeAbsolute.subCards.banner,
          presenceRegion: storeAbsolute.subCards.region,
          presenceSubChannel: storeAbsolute.subCards.subChannel,
        }}
        period={period}
        subCardSelected={selectedSubCard}
        setSubCardSelected={setSelectedSubCard}
      />
      {/* {selectedSubCard !== "" && (
        <DetailPerfectStore detailPerfectStoreData={graphicDetailStore} />
      )} */}
    </>
  );
};

export default PerfectStoreAbsolut;
