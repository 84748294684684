import React, { useContext } from "react";
import PlotlyChart from "react-plotlyjs-ts";
import { ConfigContext } from "../../../services/Context/ConfigContext";

// configiguration platform
import { ITypeGraphicHorizontalBar } from "../../../services/Hooks/OnePage/onePageTypes";

interface ownProps {
  dataGraphic?: ITypeGraphicHorizontalBar;
  color?: string | string[];
  w?: number;
  height?: number;
  maxRenge?: number;
  minRenge?: number;
  alignCenter?: boolean;
  gap?: number;
  mt?: number;
}

const MarketShareValue: React.FC<ownProps> = ({
  dataGraphic,
  color,
  height,
  maxRenge,
  minRenge,
  alignCenter,
  w,
  gap,
  mt,
}: ownProps) => {
  const { label, y, x } = dataGraphic ?? { label: [], x: [], y: [] };
  const h = height || x?.length * 34.5;
  const arrayInt = x?.map((num) => parseFloat(num.toString())) || [];

  const { config } = useContext(ConfigContext);

  const rangeXMax = Math.max.apply(null, arrayInt);
  const rangeXMin = Math.min.apply(null, arrayInt);

  let rangeXFinalMax = 0;
  let rangeXFinalMin = 0;

  if (rangeXMax > 0.0000001 && rangeXMin < -0.0000001) {
    if (minRenge && maxRenge) {
      rangeXFinalMax = rangeXMax + rangeXMax + maxRenge;
      rangeXFinalMin = rangeXMin - rangeXMax - minRenge;
    } else {
      rangeXFinalMax = rangeXMax + rangeXMax + 0.1;
      rangeXFinalMin = rangeXMin - rangeXMax - 0.1;
    }
  } else if (rangeXMax < 0) {
    if (alignCenter && minRenge) {
      const value = minRenge === 30 ? 0 : minRenge - 300;
      rangeXFinalMax = rangeXMin * 0 + value;
      rangeXFinalMin = rangeXMin * 2.7 + rangeXMin;
    } else {
      rangeXFinalMax = 0;
      rangeXFinalMin = rangeXMin * 0.8 + rangeXMin;
    }
  } else if (rangeXMax > 0) {
    rangeXFinalMax = rangeXMax * 0.5 + rangeXMax;
    rangeXFinalMin = 0;
  } else if (rangeXMin > -0.0000001) {
    rangeXFinalMax = rangeXMax + rangeXMax * 0.1;
    rangeXFinalMin = 0;
  } else if (rangeXMax === 0 && rangeXMin < 0) {
    rangeXFinalMax = Math.abs(rangeXMin) * 1.4;
    rangeXFinalMin = rangeXMin + rangeXMin * 0.4;
  }

  const defineColor = (values?: number[]): string[] => {
    const colors = values?.map((value) => {
      if (value > 0) return "#00B0F0";
      if (value < 0) return "#c00000";
      return "black";
    });

    return colors || [];
  };

  const trace = {
    meta: {
      columnNames: {
        text: "B",
        marker: { color },
        name: "name",
      },
    },
    mode: "markers",
    type: "bar",
    x,
    y: y?.map((legend, idx) => `${idx + 1}) ${legend}`),
    arrayInt,
    marker: {
      meta: { columnNames: { color: "D" } },
      color: color || defineColor(x),
    },
    text: label,
    textinfo: "value",
    hoverinfo: "y+text",
    orientation: "h",
    textposition: "outside",
  };

  const layout = {
    width: w,
    height: h,
    font: {
      color: "#404040",
      size: config.fonts.graphics,
    },
    xaxis: {
      type: "linear",
      range: [rangeXFinalMin, rangeXFinalMax],
      showgrid: false,
      zeroline: true,
      autorange: false,
      showticklabels: false,
      separatethousands: true,
      zerolinecolor: "#555",
      zerolinewidth: 0.1,
      fixedrange: true,
    },
    yaxis: {
      type: "category",
      range: [0, 0],
      autorange: "reversed",
      fixedrange: true,
      showticklabels: false,
      scaleanchor: "x",
      scaleratio: 1,
    },
    bargap: gap,
    margin: {
      b: 2,
      l: 0,
      r: 0,
      t: mt,
      pad: 0,
    },
    barmode: "relative",
    barnorm: "",
    modebar: { orientation: "v" },
    autosize: true,
    separators: ",.",
    clickmode: "none",
    hovermode: "closest",
  };

  const data = [trace];
  const configGraphic = { displayModeBar: false };
  return <PlotlyChart data={data} layout={layout} config={configGraphic} />;
};

MarketShareValue.defaultProps = {
  color: undefined,
  height: undefined,
  maxRenge: 200,
  minRenge: 0,
  mt: 2,
  alignCenter: false,
  dataGraphic: {
    label: [],
    x: [],
    y: [],
  },
  w: undefined,
  gap: 0.1,
};

export default MarketShareValue;
