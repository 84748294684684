import { useQuery, UseQueryResult } from "react-query";
import { ITableData } from "../../../components/TableWithGraphic";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";
import { ITypeGraphicHorizontalBar } from "../OnePage/onePageTypes";

interface IResponseFormat {
  topProduct: ITableData & { period: string };
  topStore: ITableData & { period: string };
}

const requestBottomTables = async (
  period: string,
  filters: IStateFilters
): Promise<IResponseFormat> => {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const topProduct = await api.get<IResponseFormat>(
    `presence_top_products/?period=${period}`,
    {
      params: {
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        coordinator:
          filters.promoFilter.commercialCoordinator.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
        gpdv: filters.promoFilter.gpdv.selectedFilterId || null,
        retailEnviroment:
          filters.promoFilter.retailEnviroment.selectedFilterId || null,
      },
    }
  );

  const topStore = await api.get<IResponseFormat>(
    `presence_top_store/?period=${period}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        segment: filters.promoFilter.segments.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        coordinator:
          filters.promoFilter.commercialCoordinator.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
        gpdv: filters.promoFilter.gpdv.selectedFilterId || null,
        retailEnviroment:
          filters.promoFilter.retailEnviroment.selectedFilterId || null,
      },
    }
  );

  const graphicOrdered: ITableData & { period: string } = {
    data: [],
    growth: {
      label: [],
      x: [],
      y: [],
    },
    header: topProduct.data.topProduct.header,
    period: topProduct.data.topProduct.period,
  };

  const graphicOrderedStore: ITableData & { period: string } = {
    data: [],
    growth: {
      label: [],
      x: [],
      y: [],
    },
    header: topStore.data.topStore.header,
    period: topStore.data.topStore.period,
  };

  const ordered = topProduct.data.topProduct.growth.x
    .map((number, index) => {
      return {
        count: index,
        value: number,
      };
    })
    .sort((number, nextNumber) => number.value - nextNumber.value);

  const orderedStore = topStore.data.topStore.growth.x
    .map((number, index) => {
      return {
        count: index,
        value: number,
      };
    })
    .sort((number, nextNumber) => number.value - nextNumber.value);

  ordered.forEach((value) => {
    graphicOrdered.growth.label.push(
      topProduct.data.topProduct.growth.label[value.count]
    );
    graphicOrdered.growth.x.push(
      topProduct.data.topProduct.growth.x[value.count]
    );
    graphicOrdered.growth.y.push(
      topProduct.data.topProduct.growth.y[value.count]
    );
    graphicOrdered.data.push(topProduct.data.topProduct.data[value.count]);
  });

  orderedStore.forEach((value) => {
    graphicOrderedStore.growth.label.push(
      topStore.data.topStore.growth.label[value.count]
    );
    graphicOrderedStore.growth.x.push(
      topStore.data.topStore.growth.x[value.count]
    );
    graphicOrderedStore.growth.y.push(
      topStore.data.topStore.growth.y[value.count]
    );
    graphicOrderedStore.data.push(topStore.data.topStore.data[value.count]);
  });

  const qtdItem = topProduct.data.topProduct.data.length;
  if (qtdItem < 10) {
    for (let index = qtdItem; index < 10; index += 1) {
      graphicOrdered.data.push(["-", "-", "-"]);
      graphicOrdered.growth?.label.push(" ");
      graphicOrdered.growth?.x.push(0);
      graphicOrdered.growth?.y.push(index.toString());
    }
  }

  const qtdItemStore = topStore.data.topStore.data.length;
  if (qtdItemStore < 10) {
    for (let index = qtdItemStore; index < 10; index += 1) {
      graphicOrderedStore.data.push(["-", "-", "-"]);
      graphicOrderedStore.growth?.label.push(" ");
      graphicOrderedStore.growth?.x.push(0);
      graphicOrderedStore.growth?.y.push(index.toString());
    }
  }

  topStore.data.topStore.period =
    months[parseInt(topStore.data?.topStore.period, 10) - 1];

  topProduct.data.topProduct.period =
    months[parseInt(topProduct.data.topProduct.period, 10) - 1];

  return {
    topProduct: graphicOrdered,
    topStore: graphicOrderedStore,
  };
};

export const useRequestBottomTables = (
  period: string,
  filters: IStateFilters
): UseQueryResult<IResponseFormat, unknown> => {
  return useQuery(
    ["BottomTables", filters],
    () => requestBottomTables(period, filters),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
};
