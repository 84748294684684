import { useQuery, UseQueryResult } from "react-query";
import { IDataFormat } from "../../../components/TableComplements";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

const requestPresenceTableEvolution = async (
  period: string,
  filters: IStateFilters,
  presenceSelected: string
): Promise<IDataFormat> => {
  const { data } = await api.get<IDataFormat>(
    `presence_${presenceSelected}_table/?period=${period}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        retailEnviroment:
          filters.promoFilter.retailEnviroment.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        coordinator:
          filters.promoFilter.commercialCoordinator.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
        gpdv: filters.promoFilter.gpdv.selectedFilterId || null,
        // segment: filters.segments.selectedFilterId || null,
      },
    }
  );
  return data;
};

export const useRequestPresenceTableEvolution = (
  period: string,
  filters: IStateFilters,
  presenceSelected: string
): UseQueryResult<IDataFormat, unknown> => {
  return useQuery(
    ["presenceTableEvolution", filters, presenceSelected],
    () => requestPresenceTableEvolution(period, filters, presenceSelected),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
};
