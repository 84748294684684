import { Button } from "@chakra-ui/react";
import { FaGratipay } from "react-icons/fa";

interface PaginationItemProps {
  isCurrent?: boolean;
  number: number;
  onPageChange: (page: number) => void;
  bg?: string;
}

export const PaginationItem: React.FC<PaginationItemProps> = ({
  isCurrent,
  number,
  onPageChange,
  bg,
}: PaginationItemProps): JSX.Element => {
  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="xs"
        w="4"
        colorScheme="blue"
        disabled
        _disabled={{
          bgColor: "#2a90b8",
          cursor: "default",
        }}
      >
        {number}
      </Button>
    );
  }

  return (
    <Button
      size="sm"
      fontSize="xs"
      w="4"
      bgColor={bg}
      _hover={{
        bg: "gray.200",
      }}
      color="white"
      onClick={() => onPageChange(number)}
    >
      {number}
    </Button>
  );
};

PaginationItem.defaultProps = {
  isCurrent: false,
  bg: "gray.400",
};
