export enum IPSAbsolutTemporalLine {
  ABS = "@MyPerfectStore/ABSOLUT",
  PERCENT = "@MyPerfectStore/PERCENT",
}

export enum IPSPontuationTemporalLine {
  YTD = "@MyPerfectStore/YTD",
  L3M = "@MyPerfectStore/L3M",
  MES = "@MyPerfectStore/MES",
}

export enum IPSMainCards {
  PERFECT_STORE_ABSOLUTE = "@MyPerfectStore/PERFECT_STORE_ABSOLUTE",
  PERFECT_STORE_PONTUATION = "@MyPerfectStore/PERFECT_STORE_PONTUATION",
  PONTUATION_KPIS = "@MyPerfectStore/PONTUATION_KPIS",
}
