import { useQuery, UseQueryResult } from "react-query";
import { IDataFormat } from "../../../components/TableComplements";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

const requestComplianceExtraExposureTable = async (
  tableType: string,
  filters: IStateFilters
): Promise<IDataFormat> => {
  const { data } = await api.get<IDataFormat>(
    `compliance_extra_exposure_${
      tableType === "banner" ? `top_${tableType}` : tableType
    }_table`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        retailEnviroment:
          filters.promoFilter.retailEnviroment.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        coordinator:
          filters.promoFilter.commercialCoordinator.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
        gpdv: filters.promoFilter.gpdv.selectedFilterId || null,

      },
    }
  );

  return data;
};

export const useRequestComplianceExtraExposureTable = (
  tableType: string,
  filters: IStateFilters
): UseQueryResult<IDataFormat, unknown> => {
  return useQuery(
    ["extraExposureTable", tableType, filters],
    () => requestComplianceExtraExposureTable(tableType, filters),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
};
