import styled from "styled-components";
import { theme } from "../../../../../styles/theme";

const gray70 = "#7A7A7A";

interface PropsBookCardContainer {
  width?: string;
  rows?: string;
  columns?: string;
  rowGap?: string;
  margin?: string;
  background?: string;
}

interface PropsBookCardContent {
  margin?: string;
}

interface PropsBookCardContentTitle {
  margin?: string;
}

interface PropsBookCardContainerImage {
  width: string;
  height: string;
  position?: string;
  top?: string;
  margin?: string;
  grid?: boolean;
  rowGap?: string;
}

interface PropsBookCardContentImage {
  width: string;
  height: string;
  shadown?: string;
  margin?: string;
  maxHeight?: string;
}

export const BookCardContainer = styled.div<PropsBookCardContainer>`
  width: ${(props) => props.width || "100%"};
  height: auto;
  min-height: 310px;
  background: ${(props) => props.background || "none"};

  display: grid;
  grid-template-rows: ${(props) => props.rows};
  grid-template-columns: ${(props) => props.columns};
  grid-row-gap: ${(props) => props.rowGap || "0"};

  margin: ${(props) => props.margin || "0 auto"};
`;

export const BookCardContentTitle = styled.div<PropsBookCardContentTitle>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};

  h1 {
    font-size: 40px;
    color: ${theme.colors.numberCards};
    margin-left: 4px;
    margin-right: 6px;
  }

  img {
    width: 32px;
    height: 32px;
    margin-right: 6px;
  }

  div {
    color: ${gray70};

    p {
      margin-bottom: 0;
    }
  }
`;

export const BookCardContainerImage = styled.div<PropsBookCardContainerImage>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  position: ${(props) => props.position};
  top: ${(props) => props.top};

  ${(props) =>
    props.grid &&
    `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
  `}

  @media only screen and (max-width: 999px) {
    height: 85%;
  }

  @media only screen and (max-width: 1100px) {
    height: 95%;
  }
`;

export const BookCardContentImage = styled.img<PropsBookCardContentImage>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-height: ${(props) => props.maxHeight || "100%"};
  object-fit: cover;
  box-shadow: ${(props) => props.shadown || "none"};
  margin: ${(props) => props.margin || "0"};

  :hover {
    cursor: pointer;
  }

  /* @media only screen and (max-width: 720px) {
    height: 80%;
  }

  @media only screen and (max-width: 999px) {
    height: 70%;
  } */
`;

export const BookCardContentBody = styled.span`
  display: flex;
  margin: auto 0;

  & > span {
    height: calc(100% - 35px);
  }
`;

export const BookCardContent = styled.div<PropsBookCardContent>`
  width: calc(100% - 5px);
  height: 100%;
  box-sizing: border-box;
  padding: 21px;
  overflow: hidden;
  margin: ${(props) => props.margin};
  display: flex;
  flex-direction: column;

  background: var(--white);
  color: var(--base-text);

  min-height: 357px;
`;

export const SubContainerImg = styled.div<{
  row?: number;
  column?: number;
  w?: string;
}>`
  display: block;
  text-align: center;
  width: ${(props) => props.w || "100%"};
  overflow: hidden;

  transition: 0.3s;

  div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 3px;
    height: fit-content;
  }

  p {
    color: #7f7f7f;
    font-weight: bold;

    @media only screen and (max-width: 1300px) {
      margin-bottom: -1.75rem;
    }

    @media only screen and (max-width: 999px) {
      margin-bottom: -3.25rem;
    }
  }

  .zoom {
    position: absolute;
    left: 50%;
    margin-left: -50px;

    width: 400px;
    height: 400px;
    z-index: 10000;
    box-shadow: rgba(0, 0, 0, 0.56) 22px 44px 92px 26px;
  }

  .zoomA {
    position: absolute;
    /* left: 50%;
    margin-left: -50px; */

    width: 400px;
    height: 400px;
    z-index: 10000;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }

  .noZoom {
    width: 100%;
    height: 90%;
    object-fit: cover;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    margin: 30% 0 10px 0;
  }

  .noZoomA {
    width: 100%;
    height: 90%;
    object-fit: cover;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    margin: 30% 0 10px 0;
  }
`;

export const AclopButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  transition: filter 0.2s;

  a {
    text-decoration: none;
    outline: 0;
  }

  button:nth-child(2) {
    background: #dc3545;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100px;
    height: 30px;
    font-size: 1rem;
    background: #a144ac;
    color: white;
    border: none;
    border-radius: 6px;

    svg {
      margin-right: 8%;
      margin-left: 8%;
    }

    :hover {
      filter: brightness(0.9);
    }
  }
`;
