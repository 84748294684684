import { Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { theme } from "../../styles/theme";
import {
  InputFilters,
  SeeDatail,
  WrapperGraphics,
} from "../Contents/Resources/Cards/container.element";
import MarketSharePeriodBarLtyYtd from "../Graphics/MarketSharePeriodBarLtyYtd/MarketSharePeriodBarLtyYtd";
import { addVoidValueGraphic } from "../Graphics/Resource/GraphicsFunctions";
import LoadingComponent from "../LoadingComponent";
import { EToggle } from "../MyExecution/CustomMainCards";
import magnifyingImg from "../../assets/img/icons/magnifying_glass_black.png";
import { ISubTableComplements } from "../../services/Hooks/MyExecution/types";
import MarketSharePeriodLtyYtd from "../Graphics/MarketSharePeriodLtyYtd/MarketSharePeriodLtyYtd";

interface IOwnProps {
  subCardSelected: string;
  isLoading: boolean;
  setSubCardSelected(card: string): void;
  data?: ISubTableComplements;
  period: string;
}

const SubTableComplements: React.FC<IOwnProps> = ({
  data,
  subCardSelected,
  isLoading,
  setSubCardSelected,
  period,
}: IOwnProps) => {
  function handleSelectedCard(id: string, section: string) {
    if (id === subCardSelected) setSubCardSelected("");
    else {
      setSubCardSelected(id);
      //   setFilterSection(section);
    }
  }

  return (
    <Flex
      css={`
        &::-webkit-scrollbar {
          background-color: darkgray;
          border-radius: 20px;
          width: 12px;
          height: 12px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background-color: ${theme.scrollColor};
        }
      `}
      overflowY="hidden"
      transition="0.8s"
      align="center"
      w="100%"
      h="fit-content"
      margin="0 0 5px 0 !important"
    >
      {data?.presenceCategory && (
        <Stack
          h="100%"
          w="100%"
          minW={`${
            data
              ? (data.presenceCategory.rightBar.x.length <= 5 && 430) ||
                data.presenceCategory.rightBar.x.length * 78
              : 553
          }px`}
          m="0"
          mr="5px"
        >
          <Flex
            h="45px"
            align="center"
            justify="center"
            w="100%"
            bg="cardHeaderBackgroundColor"
            fontWeight="bold"
            key={Math.random()}
            px="25px"
            mb="5px !important"
          >
            <Text fontSize="20px">
              Categoria{" "}
              <Text fontSize="14px" fontStyle="italic" as="span">
                {`(Periodo: ${period || "-------"})`}
              </Text>
            </Text>
          </Flex>
          <Stack
            p="5px"
            bg="white"
            position="relative"
            h="calc(100% - 50px)"
            _before={
              (subCardSelected === EToggle.PRESENCE && {
                content: `""`,
                position: "absolute",
                width: "100%",
                height: "100%",
                borderWidth: "4px",
                borderColor: "selectedColor",
                zIndex: "5",
                left: "0",
                top: "0",
              }) ||
              {}
            }
            _after={
              (subCardSelected === EToggle.PRESENCE && {
                content: '""',
                w: 0,
                h: 0,
                borderTopWidth: "15px",
                borderTopColor: "selectedColor",
                borderLeft: "solid 50px transparent",
                borderRight: "solid 50px transparent",
                position: "absolute",
                left: "calc(50% - 48px)",
                bottom: "-14px",
                zIndex: 5,
              }) ||
              {}
            }
            onClick={() =>
              handleSelectedCard(EToggle.PRESENCE, EToggle.PRESENCE)
            }
            id="id1"
            margin="0px !important"
          >
            {(isLoading && <LoadingComponent />) ||
              (data && (
                <>
                  <InputFilters right>
                    <SeeDatail width="140px" img={magnifyingImg}>
                      <i>
                        {subCardSelected === EToggle.PRESENCE
                          ? "Fechar Evolutivo"
                          : "Abrir Evolutivo"}
                      </i>
                    </SeeDatail>
                  </InputFilters>

                  <WrapperGraphics overflowX="clip">
                    <MarketSharePeriodBarLtyYtd
                      dataGraphic={addVoidValueGraphic(
                        5,
                        data?.presenceCategory.rightBar
                      )}
                      autosize
                      bgColor={
                        subCardSelected === EToggle.PRESENCE
                          ? theme.graphics.dark
                          : theme.graphics.light
                      }
                      b={45}
                      l={15}
                      r={15}
                    />
                  </WrapperGraphics>
                </>
              ))}
          </Stack>
        </Stack>
      )}
      <Stack h="100%" w="100%" minW="375px">
        <Flex
          h="45px"
          align="center"
          justify="center"
          w="100%"
          bg="cardHeaderBackgroundColor"
          fontWeight="bold"
          key={Math.random()}
          px="25px"
          mb="5px"
        >
          <Text fontSize="20px">
            Sub Canais{" "}
            <Text fontSize="14px" fontStyle="italic" as="span">
              {`(Periodo: ${period || "-------"})`}
            </Text>
          </Text>
        </Flex>
        <Stack
          p="5px"
          bg="white"
          position="relative"
          h="calc(100% - 50px)"
          _before={
            (subCardSelected === "sub_channel" && {
              content: `""`,
              position: "absolute",
              width: "100%",
              height: "100%",
              borderWidth: "4px",
              borderColor: "selectedColor",
              zIndex: "5",
              left: "0",
              top: "0",
            }) ||
            {}
          }
          _after={
            (subCardSelected === EToggle.SUB_CHANNEL && {
              content: '""',
              w: 0,
              h: 0,
              borderTopWidth: "15px",
              borderTopColor: "selectedColor",
              borderLeft: "solid 50px transparent",
              borderRight: "solid 50px transparent",
              position: "absolute",
              left: "calc(50% - 48px)",
              bottom: "-14px",
              zIndex: 5,
            }) ||
            {}
          }
          onClick={() => handleSelectedCard("sub_channel", "subchannel")}
          id="id3"
          margin="0px !important"
        >
          {(isLoading && <LoadingComponent />) || (
            <>
              <InputFilters right padding="0">
                <SeeDatail width="140px" img={magnifyingImg}>
                  <i>
                    {subCardSelected === "sub_channel"
                      ? "Fechar Evolutivo"
                      : "Abrir Evolutivo"}
                  </i>
                </SeeDatail>
              </InputFilters>
              {data?.presenceSubChannel.periodLine && (
                <MarketSharePeriodLtyYtd
                  lineWidth={false}
                  dataGraphic={data?.presenceSubChannel.periodLine}
                  colors="#C00000"
                  ml={
                    data.presenceSubChannel.periodLine.label.length > 4
                      ? 13
                      : 20
                  }
                  mr={
                    data.presenceSubChannel.periodLine.label.length > 4
                      ? 13
                      : 190
                  }
                  autosize
                />
              )}
              <WrapperGraphics overflowX="clip">
                <MarketSharePeriodBarLtyYtd
                  dataGraphic={addVoidValueGraphic(
                    5,
                    data?.presenceSubChannel.rightBar
                  )}
                  autosize
                  bgColor={
                    subCardSelected === "sub_channel"
                      ? theme.graphics.dark
                      : theme.graphics.light
                  }
                  b={45}
                />
              </WrapperGraphics>
            </>
          )}
        </Stack>
      </Stack>
      <Stack h="100%" w="100%" mx="5px" minW="375px">
        <Flex
          h="45px"
          align="center"
          justify="center"
          w="100%"
          bg="cardHeaderBackgroundColor"
          fontWeight="bold"
          key={Math.random()}
          px="25px"
          mb="5px"
        >
          <Text fontSize="20px">
            Regionais{" "}
            <Text fontSize="14px" fontStyle="italic" as="span">
              {`(Periodo: ${period || "-------"})`}
            </Text>
          </Text>
        </Flex>
        <Stack
          p="5px"
          bg="white"
          position="relative"
          h="calc(100% - 50px)"
          _before={
            (subCardSelected === EToggle.REGION && {
              content: `""`,
              position: "absolute",
              width: "100%",
              height: "100%",
              borderWidth: "4px",
              borderColor: "selectedColor",
              zIndex: "5",
              left: "0",
              top: "0",
            }) ||
            {}
          }
          _after={
            (subCardSelected === EToggle.REGION && {
              content: '""',
              w: 0,
              h: 0,
              borderTopWidth: "15px",
              borderTopColor: "selectedColor",
              borderLeft: "solid 50px transparent",
              borderRight: "solid 50px transparent",
              position: "absolute",
              left: "calc(50% - 48px)",
              bottom: "-14px",
              zIndex: 5,
            }) ||
            {}
          }
          onClick={() => handleSelectedCard(EToggle.REGION, EToggle.REGION)}
          id="id2"
          margin="0px !important"
        >
          {(isLoading && <LoadingComponent />) ||
            (data && (
              <>
                <InputFilters right padding="0">
                  <SeeDatail width="140px" img={magnifyingImg}>
                    <i>
                      {subCardSelected === EToggle.REGION
                        ? "Fechar Evolutivo"
                        : "Abrir Evolutivo"}
                    </i>
                  </SeeDatail>
                </InputFilters>
                {data.presenceRegion.periodLine && (
                  <MarketSharePeriodLtyYtd
                    lineWidth={false}
                    dataGraphic={data?.presenceRegion.periodLine}
                    colors="#C00000"
                    ml={13}
                    mr={13}
                    autosize
                  />
                )}
                <WrapperGraphics overflowX="clip">
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={addVoidValueGraphic(
                      5,
                      data?.presenceRegion.rightBar
                    )}
                    autosize
                    bgColor={
                      subCardSelected === EToggle.REGION
                        ? theme.graphics.dark
                        : theme.graphics.light
                    }
                    b={45}
                  />
                </WrapperGraphics>
              </>
            ))}
        </Stack>
      </Stack>
      <Stack
        h="100%"
        w="590px"
        minWidth="max-content"
        minW={`calc(${data?.presenceCategory ? "40%" : "48%"} - 0px)`}
      >
        <Flex
          h="45px"
          align="center"
          justify="center"
          w="100%"
          bg="cardHeaderBackgroundColor"
          fontWeight="bold"
          key={Math.random()}
          px="25px"
          mb="5px"
        >
          <Text fontSize="20px">
            Top 10 Bandeiras{" "}
            <Text fontSize="14px" fontStyle="italic" as="span">
              {`(Periodo: ${period || "-------"})`}
            </Text>
          </Text>
        </Flex>
        <Stack
          p="5px"
          bg="white"
          position="relative"
          h="calc(100% - 50px)"
          _before={
            (subCardSelected === "banner" && {
              content: `""`,
              position: "absolute",
              width: "100%",
              height: "100%",
              borderWidth: "4px",
              borderColor: "selectedColor",
              zIndex: "5",
              left: "0",
              top: "0",
            }) ||
            {}
          }
          _after={
            (subCardSelected === "banner" && {
              content: '""',
              w: 0,
              h: 0,
              borderTopWidth: "15px",
              borderTopColor: "selectedColor",
              borderLeft: "solid 50px transparent",
              borderRight: "solid 50px transparent",
              position: "absolute",
              left: "calc(50% - 48px)",
              bottom: "-14px",
              zIndex: 5,
            }) ||
            {}
          }
          onClick={() => handleSelectedCard("banner", "brand")}
          id="id4"
          margin="0px !important"
        >
          {(isLoading && <LoadingComponent />) || (
            <>
              <InputFilters right padding="0">
                <SeeDatail width="140px" img={magnifyingImg}>
                  <i>
                    {subCardSelected === "banner"
                      ? "Fechar Evolutivo"
                      : "Abrir Evolutivo"}
                  </i>
                </SeeDatail>
              </InputFilters>
              {data?.presenceBanner.periodLine && (
                <MarketSharePeriodLtyYtd
                  lineWidth={false}
                  dataGraphic={data?.presenceBanner.periodLine}
                  colors="#C00000"
                  autosize
                />
              )}
              <WrapperGraphics overflowX="clip">
                <MarketSharePeriodBarLtyYtd
                  dataGraphic={addVoidValueGraphic(
                    10,
                    data?.presenceBanner.rightBar
                  )}
                  autosize
                  bgColor={
                    subCardSelected === "banner"
                      ? theme.graphics.dark
                      : theme.graphics.light
                  }
                  b={45}
                  r={10}
                  l={10}
                />
              </WrapperGraphics>
            </>
          )}
        </Stack>
      </Stack>
    </Flex>
  );
};

SubTableComplements.defaultProps = {
  data: {
    presenceBanner: {
      rightBar: {
        label: [],
        x: [],
        y: [],
      },
    },
    presenceCategory: {
      rightBar: {
        label: [],
        x: [],
        y: [],
      },
    },
    presenceRegion: {
      rightBar: {
        label: [],
        x: [],
        y: [],
      },
    },
    presenceSubChannel: {
      rightBar: {
        label: [],
        x: [],
        y: [],
      },
    },
  },
};

export default SubTableComplements;
