import { ChangeEvent } from "react";
import { Flex, Input } from "@chakra-ui/react";
import ComponentFilter from "../../Filters/filters";
import { filterComponent } from "../../../store/Navigation/types";
import { stateMarketShare } from "../../../store/SideFilters/RequestFilters/types";
import {
  IShowFilters,
  IStateFilters,
  TPromoFilter,
} from "../../../store/SideFilters/types";

import {
  filterCategory,
  filterBanner,
  filterBrand,
  filterRegion,
  filterSegments,
  filterSubChannel,
  filterUF,
  filterExecutionType,
  filterExecutionLeverage,
  filterBusinessUnitId,
  filterMerchandising,
  filterDirector,
  filteRetailEnviroment,
  // filterCoordinator,
  filterSales,
  filterManage,
  filterGPDV,
} from ".";
import { convertDate } from "../../../Resource/functions";

interface IChangeFilter {
  changeFilterIsPromo(dataFilter: TPromoFilter): void;
  changeDateInitialFilter(payload: string): void;
  changeDateFinalFilter(payload: string): void;
  changeCnpjStore(payload: string): void;
}

const FiltersList = (
  data: stateMarketShare,
  SFilters: IStateFilters,
  showFilters: IShowFilters,
  AFilters?: IChangeFilter
): filterComponent[] => {
  const mslFilters = data.data.marketShare?.subviewCategory.main;

  const getMaxDate = (isInitialDate: boolean): string => {
    const date = new Date();

    const day = isInitialDate ? 30 : 0;

    const newData = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - day
    ).toLocaleDateString();

    return convertDate(newData, "");
  };

  // const maxDate = `${date.getFullYear()}-0${
  //   date.getMonth() + 1
  // }-${date.getDate()}`;
  const myExecutionFilters: filterComponent[] = [];

  if (showFilters.period && AFilters) {
    myExecutionFilters.push({
      name: "Filtro Período",
      componentFilters: [
        <Input
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            AFilters.changeDateInitialFilter(e.target.value)
          }
          name="initialDate"
          id="initial"
          value={SFilters.promoFilter.dateInitial}
          h="27px"
          max={getMaxDate(false)}
          // min={getMaxDate(true)}
          key={Math.random()}
          bg="white"
          color="black"
          fontSize="inherit"
        />,
        <Input
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            AFilters.changeDateFinalFilter(e.target.value)
          }
          name="finalDate"
          id="final"
          h="25px"
          value={SFilters.promoFilter.dateFinal}
          max={getMaxDate(false)}
          // min={getMaxDate(true)}
          key={Math.random()}
          bg="white"
          color="black"
          fontSize="inherit"
        />,
      ],
    });
  }

  if (showFilters.typeExecution) {
    const filters: JSX.Element[] = [];
    filters.push(
      <ComponentFilter
        listCategory={data.data.myExecution?.subviewOSA.main.executions_type}
        selectedItemName={SFilters.promoFilter.executionType.selectedFilterName}
        filter={filterExecutionType}
        key={Math.random()}
      />
    );

    if (showFilters.executionLeverage)
      filters.push(
        <ComponentFilter
          listCategory={
            SFilters.promoFilter.executionLeverage?.listFilters || []
          }
          selectedItemName={
            SFilters.promoFilter.executionLeverage.selectedFilterName
          }
          filter={filterExecutionLeverage}
          key={Math.random()}
        />
      );

    myExecutionFilters.push({
      name: "Tipo de Execução",
      componentFilters: filters,
    });
  }

  if (
    showFilters.businessUnitId ||
    showFilters.category ||
    showFilters.subCategory ||
    showFilters.segment
  ) {
    const filtersProduct: JSX.Element[] = [];

    const bu = showFilters.isPromo
      ? data.data.myExecution?.subviewOSA.main.business_unit_promo
      : data.data.myExecution?.subviewOSA.main.business_unit;

    const stateFilter = showFilters.isPromo
      ? SFilters.promoFilter
      : SFilters.filter;

    if (showFilters.businessUnitId) {
      filtersProduct.push(
        <ComponentFilter
          listCategory={bu}
          selectedItemName={stateFilter.businessUnitId.selectedFilterName}
          filter={filterBusinessUnitId}
          key={Math.random()}
        />
      );
    }

    if (showFilters.category)
      filtersProduct.push(
        <ComponentFilter
          listCategory={
            showFilters.mainCategory
              ? (showFilters.isPromo &&
                  data.data.myExecution?.subviewOSA.secondary
                    .categories_promo) ||
                data.data.myExecution?.subviewOSA.secondary.categories
              : stateFilter.category.listFilters || []
          }
          selectedItemName={stateFilter.category.selectedFilterName}
          filter={filterCategory}
          key={Math.random()}
        />
      );

    if (showFilters.subCategory)
      filtersProduct.push(
        <ComponentFilter
          listCategory={stateFilter.brand?.listFilters || []}
          selectedItemName={stateFilter.brand.selectedFilterName}
          filter={filterBrand}
          key={Math.random()}
        />
      );

    if (showFilters.segment)
      filtersProduct.push(
        <ComponentFilter
          listCategory={stateFilter.segments?.listFilters || []}
          selectedItemName={stateFilter.segments.selectedFilterName}
          filter={filterSegments}
          key={Math.random()}
        />
      );

    myExecutionFilters.push({
      name: "Filtro Produto",
      componentFilters: filtersProduct,
    });
  }

  if (showFilters.priorityItens) {
    myExecutionFilters.push({
      name: "Filtro Itens Prioritários",
      componentFilters: [
        <ComponentFilter
          listCategory={[]}
          selectedItemName={SFilters.promoFilter.subChannel.selectedFilterName}
          filter={filterSubChannel}
          key={Math.random()}
        />,
      ],
    });
  }

  if (showFilters.subChannel) {
    myExecutionFilters.push({
      name: "Filtro SubCanal",
      componentFilters: [
        <ComponentFilter
          listCategory={mslFilters?.subChannel}
          selectedItemName={SFilters.promoFilter.subChannel.selectedFilterName}
          filter={filterSubChannel}
          key={Math.random()}
          showFilters={showFilters}
          disabled={SFilters.promoFilter.storeCNPJ.length > 0}
        />,
      ],
    });
  }

  if (showFilters.banner) {
    myExecutionFilters.push({
      name: "Filtro Bandeiras",
      componentFilters: [
        <ComponentFilter
          listCategory={SFilters.promoFilter.banner.listFilters || []}
          selectedItemName={SFilters.promoFilter.banner.selectedFilterName}
          filter={filterBanner}
          key={Math.random()}
          showFilters={showFilters}
          disabled={SFilters.promoFilter.storeCNPJ.length > 0}
        />,
      ],
    });
  }

  if (showFilters.storeCNPJ) {
    const filters: JSX.Element[] = [];
    filters.push(
      <Flex bg="white" borderRadius="4px" overflow="hidden">
        <Input
          bg="white"
          color="black"
          height="27px"
          px="10px"
          fontSize="12px"
          maxLength={14}
          key={Math.random()}
          defaultValue={SFilters.promoFilter.storeCNPJ}
          onBlur={(e) => {
            AFilters?.changeFilterIsPromo({
              ...SFilters.promoFilter,
              subChannel: { selectedFilterId: "", selectedFilterName: "" },
              banner: {
                selectedFilterId: "",
                selectedFilterName: "",
                listFilters: [],
              },
              region: {
                selectedFilterId: "",
                selectedFilterName: "",
                listFilters: [],
              },
              uf: {
                selectedFilterId: "",
                selectedFilterName: "",
                listFilters: [],
              },
            });
            AFilters?.changeCnpjStore(e.target.value);
          }}
          placeholder="00000000000000"
        />
      </Flex>
    );

    myExecutionFilters.push({
      name: "Filtro CNPJ",
      componentFilters: filters,
    });
  }

  if (showFilters.retailEnviroment) {
    myExecutionFilters.push({
      name: "Filtro Ambiente de varejo",
      componentFilters: [
        <ComponentFilter
          listCategory={mslFilters.retail_enviroment || []}
          selectedItemName={SFilters.promoFilter.retailEnviroment.selectedFilterName}
          filter={filteRetailEnviroment}
          key={Math.random()}
          // disabled={SFilters.promoFilter.storeCNPJ.length > 0}
        />,
      ],
    });
  }

  if (showFilters.gpdv) {
    myExecutionFilters.push({
      name: "Filtro GPDV",
      componentFilters: [
        <ComponentFilter
          listCategory={mslFilters.gpdv || []}
          selectedItemName={SFilters.promoFilter.gpdv.selectedFilterName}
          filter={filterGPDV}
          key={Math.random()}
        />,
      ],
    });
  }

  if (showFilters.region) {
    const filters: JSX.Element[] = [];
    filters.push(
      <ComponentFilter
        listCategory={mslFilters?.region}
        selectedItemName={SFilters.promoFilter.region.selectedFilterName}
        filter={filterRegion}
        key={Math.random()}
        disabled={SFilters.promoFilter.storeCNPJ.length > 0}
      />
    );

    if (showFilters.uf)
      filters.push(
        <ComponentFilter
          listCategory={SFilters.promoFilter.uf.listFilters || []}
          selectedItemName={SFilters.promoFilter.uf.selectedFilterName}
          filter={filterUF}
          key={Math.random()}
          disabled={SFilters.promoFilter.storeCNPJ.length > 0}
        />
      );

    myExecutionFilters.push({
      name: "Filtro Região",
      componentFilters: filters,
    });
  }

  if (showFilters.commercialStructureDirector) {
    const filters: JSX.Element[] = [];
    filters.push(
      <ComponentFilter
        listCategory={data.data.commercial_structure?.director || []}
        selectedItemName={
          SFilters.promoFilter.commercialDirector.selectedFilterName
        }
        filter={filterDirector}
        key={Math.random()}
      />
    );

    if (showFilters.commercialStructureManager)
      filters.push(
        <ComponentFilter
          listCategory={
            SFilters.promoFilter.commercialManager.listFilters || []
          }
          selectedItemName={
            SFilters.promoFilter.commercialManager.selectedFilterName
          }
          filter={filterManage}
          key={Math.random()}
        />
      );

    // if (showFilters.commercialStructurCoordinator)
    //   filters.push(
    //     <ComponentFilter
    //       listCategory={
    //         SFilters.promoFilter.commercialCoordinator.listFilters || []
    //       }
    //       selectedItemName={
    //         SFilters.promoFilter.commercialCoordinator.selectedFilterName
    //       }
    //       filter={filterCoordinator}
    //       key={Math.random()}
    //     />
    //   );

    if (showFilters.commercialStructureSales)
      filters.push(
        <ComponentFilter
          listCategory={SFilters.promoFilter.commercialSaler.listFilters || []}
          selectedItemName={
            SFilters.promoFilter.commercialSaler.selectedFilterName
          }
          filter={filterSales}
          key={Math.random()}
        />
      );

    myExecutionFilters.push({
      name: "Filtro Estrutura Comercial",
      componentFilters: filters,
    });
  }

  if (showFilters.merchandising) {
    const filters: JSX.Element[] = [];
    filters.push(
      <ComponentFilter
        listCategory={SFilters.promoFilter.merchandising.listFilters || []}
        selectedItemName={SFilters.promoFilter.merchandising.selectedFilterName}
        filter={filterMerchandising}
        key={Math.random()}
      />
    );

    myExecutionFilters.push({
      name: "Filtro Merchandising",
      componentFilters: filters,
    });
  }

  return myExecutionFilters;
};

export default FiltersList;
