import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import React, { ReactNode, useContext } from "react";
import logo from "../../../../../assets/TradexLogos/tradex.png";
import colaboration from "../../../../../assets/img/icons/GeneratePDF/colaboration.png";
import background from "../../../../../assets/img/icons/GeneratePDF/background-2.png";
import mapa from "../../../../../assets/img/icons/mapa.png";
import calendar from "../../../../../assets/img/icons/calendar.png";
import car from "../../../../../assets/img/icons/cart.png";
import storeIcon from "../../../../../assets/img/icons/small_store.png";
import basket from "../../../../../assets/img/icons/cesta.png";
import drugstore from "../../../../../assets/img/icons/farmacia.png";
import storeEvidence from "../../../../../assets/Menu/store.png";
import notImage from "../../../../../assets/img/icons/notImage.png";
import {
  cover,
  evidenceStyle,
  layout,
  premiseBook,
  stylePremiseItem,
} from "./style";
import { FormatStringDataBr } from "../../../../../components/Graphics/Resource/GraphicsFunctions";
import { theme } from "../../../../../styles/theme";
import { formatedDateTimeToBr } from "../../../../../Resource/functions";
import { ConfigContext } from "../../../../../services/Context/ConfigContext";
import { IFormatPlanogram } from "../types";
import CardEmpty from "./cardEmpty";

interface ILayoutDefault {
  children: ReactNode;
}

const LayoutDefault: React.FC<ILayoutDefault> = (props: ILayoutDefault) => {
  const { config } = useContext(ConfigContext);

  return (
    <Page>
      <View style={layout.viewHeader}>
        <Image src={colaboration} style={layout.logoColaboration} />
        <Text style={{ paddingTop: 10 }}>
          {config.general.industryName}{" "}
          <Text style={{ fontWeight: "bold" }}>PROMO</Text>
        </Text>
        <Text style={{ color: theme.colors.numberCards, fontWeight: "heavy" }}>
          BOOK FOTOGRÁFICO
        </Text>
        <Image src={logo} style={layout.logo} />
      </View>
      <View style={layout.viewContent}>{props.children}</View>
      <View style={layout.footer}>
        <Text>Material confidencial</Text>
      </View>
    </Page>
  );
};

interface IDataPremise {
  number: string;
  subIcon: string;
  text1: string;
  subText1: string;
  text2: string;
  subText2: string;
  text3?: string;
  subText3?: string;
  iconMain: string;
  width: string;
}

const PremiseItens: React.FC<IDataPremise> = (props: IDataPremise) => {
  return (
    <View style={stylePremiseItem.mainWrapper}>
      <View style={stylePremiseItem.box}>
        <View style={stylePremiseItem.wrapperView}>
          <Text style={stylePremiseItem.number}>{props.number}</Text>
          <Image style={stylePremiseItem.image} src={props.subIcon} />
          <View>
            <Text>{props.text1}</Text>
            <Text>{props.subText1}</Text>
          </View>
        </View>
        <View style={stylePremiseItem.boxInfo}>
          <Text style={{ fontWeight: "bold" }}>{props.text2}</Text>
          <Text>{props.subText2}</Text>
        </View>
        <View style={stylePremiseItem.boxInfo}>
          <Text style={{ fontWeight: "bold" }}>{props.text3}</Text>
          <Text>{props.subText3}</Text>
        </View>
      </View>
      <View style={stylePremiseItem.icons}>
        <Image src={props.iconMain} style={{ bottom: 0, width: props.width }} />
      </View>
    </View>
  );
};

PremiseItens.defaultProps = {
  text3: "",
  subText3: "",
};

interface IDataEvidence {
  data: {
    store: string;
    visited: string;
    city: string;
    uf: string;
    banner: string;
    brand?: string;
    promoter: string;
  };
  imgBefore?: string[];
  imgAfter?: string[];
  evidenceExtraExposure?: string[];
}

const EvidencePage: React.FC<IDataEvidence> = (props: IDataEvidence) => {
  const verificationLink = (link: string) => {
    return link.split("?", 1)[0];
  };

  return (
    <View style={evidenceStyle.wrapperBox}>
      {(props.evidenceExtraExposure && (
        <View style={evidenceStyle.box}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {(props.evidenceExtraExposure[0] && (
              <Image
                src={{ uri: props.evidenceExtraExposure[0], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                style={{ ...evidenceStyle.image, paddingLeft: "1px" }}
              />
            )) || <CardEmpty numberCard="1" ml="1px" />}
            {(props.evidenceExtraExposure[1] && (
              <Image
                src={{ uri: props.evidenceExtraExposure[1], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                style={{ ...evidenceStyle.image, paddingLeft: "1px" }}
              />
            )) || <CardEmpty numberCard="2" ml="1px" />}
          </View>
          <View
            style={{
              height: "7px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              paddingHorizontal: 11,
            }}
          >
            <View
              style={{
                height: "1px",
                backgroundColor: "#dbdbdb",
                width: "100%",
              }}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {(props.evidenceExtraExposure[2] && (
              <Image
                src={{ uri: props.evidenceExtraExposure[2], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                style={evidenceStyle.image}
              />
            )) || <CardEmpty numberCard="3" ml="1px" />}
            {(props.evidenceExtraExposure[3] && (
              <Image
                src={{ uri: props.evidenceExtraExposure[3], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                style={{ ...evidenceStyle.image, paddingLeft: "1px" }}
              />
            )) || <CardEmpty numberCard="4" ml="1px" />}
          </View>
        </View>
      )) || (
        <View style={evidenceStyle.wrapperEvidence}>
          <View style={evidenceStyle.box}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {(props.imgBefore && props.imgBefore[0] && (
                <Image
                  src={{ uri: props.imgBefore[0], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                  style={{ ...evidenceStyle.image, paddingLeft: "1px" }}
                />
              )) || <CardEmpty numberCard="1" ml="1px" />}
              {(props.imgBefore && props.imgBefore[1] && (
                <Image
                  src={{ uri: props.imgBefore[1], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                  style={{ ...evidenceStyle.image, paddingLeft: "1px" }}
                />
              )) || <CardEmpty numberCard="2" ml="1px" />}
            </View>
            <View
              style={{
                height: "7px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 11,
              }}
            >
              <View
                style={{
                  height: "1px",
                  backgroundColor: "#dbdbdb",
                  width: "100%",
                }}
              />
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {(props.imgBefore && props.imgBefore[2] && (
                <Image
                  src={{ uri: props.imgBefore[2], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                  style={{ ...evidenceStyle.image, paddingLeft: "1px" }}
                />
              )) || <CardEmpty numberCard="3" ml="1px" />}
              {(props.imgBefore && props.imgBefore[3] && (
                <Image
                  src={{ uri: props.imgBefore[3], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                  style={{ ...evidenceStyle.image, paddingLeft: "1px" }}
                />
              )) || <CardEmpty numberCard="4" ml="1px" />}
            </View>
            <Text style={{ marginTop: 20 }}>Antes</Text>
          </View>
          <View
            style={{
              flex: 1,
              marginBottom: 43,
              marginTop: 5,
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "1px",
                backgroundColor: "#dbdbdb",
                marginTop: "10px",
                height: "220px",
                borderRadius: "2px",
              }}
            />
            <View
              style={{
                width: "1px",
                backgroundColor: "#dbdbdb",
                marginTop: "22.5px",
                height: "220px",
                borderRadius: "5px",
              }}
            />
          </View>
          <View style={{ ...evidenceStyle.box }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {(props.imgAfter && props.imgAfter[0] && (
                <Image
                  src={{ uri: props.imgAfter[0], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                  style={evidenceStyle.image}
                />
              )) || <CardEmpty numberCard="1" />}
              {(props.imgAfter && props.imgAfter[1] && (
                <Image
                  src={{ uri: props.imgAfter[1], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                  style={evidenceStyle.image}
                />
              )) || <CardEmpty numberCard="2" ml="1px" />}
            </View>
            <View
              style={{
                height: "6px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 11,
              }}
            >
              <View
                style={{
                  height: "1px",
                  backgroundColor: "#dbdbdb",
                  width: "100%",
                }}
              />
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {(props.imgAfter && props.imgAfter[2] && (
                <Image
                  src={{ uri: props.imgAfter[2], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                  style={evidenceStyle.image}
                />
              )) || <CardEmpty numberCard="3" />}
              {(props.imgAfter && props.imgAfter[3] && (
                <Image
                  src={{ uri: props.imgAfter[3], method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                  style={evidenceStyle.image}
                />
              )) || <CardEmpty numberCard="4" ml="1px" />}
            </View>
            <Text style={{ marginTop: 20 }}>Depois</Text>
          </View>
        </View>
      )}
      <View style={evidenceStyle.footer}>
        <Image style={evidenceStyle.icon} src={storeEvidence} />
        <View>
          <Text>{props.data.store || "Não informado"}</Text>
          <Text>
            Data da visita:{" "}
            {formatedDateTimeToBr(props.data.visited) || "Não informado"}
          </Text>
          <Text>Cidade: {props.data.city || "Não informado"}</Text>
          <Text>UF: {props.data.uf || "Não informado"}</Text>
          <Text>Bandeira: {props.data.banner || "Não informado"}</Text>
          <Text>Promotor: {props.data.promoter || "Não informado"}</Text>
          {props.data.brand && (
            <Text>Marca: {props.data.brand || "Não informado"}</Text>
          )}
        </View>
      </View>
    </View>
  );
};

EvidencePage.defaultProps = {
  imgAfter: undefined,
  imgBefore: undefined,
  evidenceExtraExposure: undefined,
};

interface IFiltersSelected {
  initialDate: string;
  finishDate: string;
  subChannel: string;
  banner: string;
  region: string;
  uf: string;
  category: string;
  brandSelected: string;
  segmentSelected: string;
}

interface IOwnProps {
  data: IFormatPlanogram[];
  filters: IFiltersSelected;
  industryName: string;
}

const MountPDF: React.FC<IOwnProps> = ({
  data,
  filters,
  industryName,
}: IOwnProps): JSX.Element => {
  const { config } = useContext(ConfigContext);

  return (
    <Document title="PROMO" author={industryName} language="portuguese">
      <Page key={Math.random()}>
        <Image src={background} style={cover.background} />
        <View style={cover.wrapper} wrap={false}>
          <View style={cover.header}>
            <Image src={logo} style={cover.logo} />
          </View>
          <View style={cover.boxTitle}>
            <View style={cover.industryNameBox} />
            <Text style={cover.industryName}>{industryName}</Text>
            <View style={cover.subTitleBox}>
              <Text style={{ marginBottom: 5 }}>
                {config.general.industryName}{" "}
                <Text style={{ fontWeight: "ultrabold" }}>PROMO</Text>
              </Text>
              <Text>Book de fotos</Text>
            </View>
          </View>
          <Text style={cover.footer}>Material confidencial</Text>
        </View>
      </Page>
      <LayoutDefault key={Math.random()}>
        <View style={premiseBook.main}>
          <Text style={premiseBook.title}>PREMISSAS DESTE BOOK</Text>
          <View style={premiseBook.content}>
            <PremiseItens
              number="1"
              iconMain={mapa}
              subIcon={calendar}
              text1="Fonte:"
              subText1={`${industryName} PROMO`}
              text2="Período Selecionado:"
              subText2={`${
                FormatStringDataBr(filters.initialDate) || "xx/xx/xxxx"
              } a ${FormatStringDataBr(filters.finishDate) || "xx/xx/xxxx"}`}
              text3="Praça Selecionada:"
              subText3={`Região: ${filters.region || "Todas"} | Uf: ${
                filters.uf || "Todas"
              }`}
              width="250"
            />
            <PremiseItens
              number="2"
              iconMain={basket}
              subIcon={car}
              text1="Categoria:"
              subText1={`${filters.category || "Todas"}`}
              text2="Marca Selecionadas:"
              subText2={`${filters.brandSelected || "Todas"}`}
              width="130"
            />
            <PremiseItens
              number="3"
              iconMain={drugstore}
              subIcon={storeIcon}
              text1="Canal:"
              subText1={`${filters.subChannel || "Todas"}`}
              text2="Bandeiras Selecionadas:"
              subText2={`${filters.banner || "Todas"}`}
              width="190"
            />
          </View>
        </View>
      </LayoutDefault>
      {data.map((store) => (
        <React.Fragment key={Math.random()}>
          <LayoutDefault key={Math.random()}>
            <Text
              style={{
                width: "70%",
                paddingBottom: 30,
                textAlign: "center",
                color: "#000040",
                fontSize: 25,
              }}
            >
              {store.banner}
            </Text>
          </LayoutDefault>
          {store.planogram.map((planogram) => (
            <LayoutDefault key={Math.random()}>
              <View style={premiseBook.main}>
                <Text style={premiseBook.title}>
                  {planogram.executionLavarage.toUpperCase()}
                </Text>
                <EvidencePage
                  imgBefore={planogram?.imgBefore}
                  imgAfter={planogram?.imgAfter}
                  evidenceExtraExposure={planogram?.evidenceExtraExposure}
                  data={planogram.description}
                />
              </View>
            </LayoutDefault>
          ))}
        </React.Fragment>
      ))}
      {/* <LayoutDefault key={Math.random()}>
        <View style={premiseBook.main}>
          <Text style={premiseBook.title}>FEEDBACK SEMANAL</Text>
          <FeedBackItem
            img={raia}
            text={`DROGA RAIA: Maioria das lojas da Rede, os produtos ${industryName.toLowerCase()} permanecem dentro do balcão de vendas.`}
          />
          <FeedBackItem
            img={popular}
            text="PREÇO POPULAR: Produtos dentro do balcão, algumas não permite nem tirar foto."
          />
          <FeedBackItem
            img={pacheco}
            text="PACHECO: PACHECO JACAREPAGUA, todos produtos dentro do balcão, não permite abastecer, nem entrar na parte de dentro."
          />
        </View>
      </LayoutDefault> */}
      <Page key={Math.random()}>
        <Image src={background} style={cover.background} />
        <View style={cover.wrapper} wrap={false}>
          <View style={cover.header}>
            <Image src={logo} style={cover.logo} />
          </View>
          <View style={cover.boxTitle}>
            <View style={cover.industryNameBox} />
            <Text style={[cover.industryName, { top: 308 }]}>OBRIGADO</Text>
            <View style={cover.subTitleBox}>
              <Text style={{ fontWeight: "ultrabold", fontSize: 20 }}>
                atendimento@tradexsolutions.com.br
              </Text>
            </View>
          </View>
          <Text style={cover.footer}>Material confidencial</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MountPDF;
