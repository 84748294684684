import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

import { ITypeGraphicPeriodBar } from "../OnePage/onePageTypes";

export interface IPrice {
  rightBar: ITypeGraphicPeriodBar;
  leftBar: ITypeGraphicPeriodBar;
  target: number;
}

const requestPrice = async (
  period: string,
  filters: IStateFilters
): Promise<IPrice> => {
  const price = await api.get<{ data: IPrice }>(`price/?period=${period}`, {
    params: {
      businessUnit: filters.promoFilter.businessUnitId.selectedFilterId || null,
      category: filters.promoFilter.category.selectedFilterId || null,
      subCategory: filters.promoFilter.brand.selectedFilterId || null,
      subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
      retailEnviroment:
        filters.promoFilter.retailEnviroment.selectedFilterId || null,
      banner: filters.promoFilter.banner.selectedFilterId || null,
      region: filters.promoFilter.region.selectedFilterId || null,
      uf: filters.promoFilter.uf.selectedFilterId || null,
      director: filters.promoFilter.commercialDirector.selectedFilterId || null,
      manager: filters.promoFilter.commercialManager.selectedFilterId || null,
      coordinator:
        filters.promoFilter.commercialCoordinator.selectedFilterId || null,
      customer_sales_rep:
        filters.promoFilter.commercialSaler.selectedFilterId || null,
      cnpj: filters.promoFilter.storeCNPJ || null,
      gpdv: filters.promoFilter.gpdv.selectedFilterId || null,
    },
  });

  return price.data.data;
};

export const useRequestPrice = (
  period: string,
  filters: IStateFilters
): UseQueryResult<IPrice, unknown> => {
  return useQuery(
    ["presencePrice", period, filters],
    () => requestPrice(period, filters),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
};
