/* eslint-disable camelcase */
import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

interface ICommomData {
  storeName: string;
  storeCnpj: string;
  storeBanner: string;
  storeSubChannel: string;
  storeUf: string;
  storeCity: string;
  promoter: string;
  executionLeverage: string;
  typeExecutionLeverage: string;
  orderName: string;
  date: string;
}

interface IEvidenceExtraExposure {
  evidences: string[];
  brand: string;
}

interface IEvidence {
  // eslint-disable-next-line camelcase
  banner_logo: string;
  evidencesBefore: string[];
  evidencesAfter: string[];
}

export type TEvidenceExtraExposure = ICommomData & IEvidenceExtraExposure;
export type TEvidence = ICommomData & IEvidence;
export type TGroupedPayload = TEvidence | TEvidenceExtraExposure;

export interface IEvidences {
  executionsEvidences: TEvidence[];
  executionsEvidencesExtraExposure: TEvidenceExtraExposure[];
  numberOfStores: number;
}

export interface IReturnEvidence {
  evidences: TGroupedPayload[];
  numberOfStores: number;
}

async function requestCategory(filters: IStateFilters, industryId?: string) {
  try {
    const response = await api.get<IEvidences>("/evidences/", {
      params: {
        page: 1,
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        subChannel:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.subChannel.selectedFilterId || null
            : null,
        banner:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.banner.selectedFilterId || null
            : null,
        region:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.region.selectedFilterId || null
            : null,
        uf:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.uf.selectedFilterId || null
            : null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        industry: industryId,
        startDate:
          filters.promoFilter.dateInitial === "dd/mm/aaaa"
            ? null
            : filters.promoFilter.dateInitial,
        endDate:
          filters.promoFilter.dateFinal === "dd/mm/aaaa"
            ? null
            : filters.promoFilter.dateFinal,
        executionType:
          filters.promoFilter.executionType.selectedFilterId || null,
        executionLeverage:
          filters.promoFilter.executionLeverage.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
        gpdv_id: filters.promoFilter.gpdv.selectedFilterId || null,

      },
    });

    console.log(response.data.executionsEvidences);

    const returnEvidence: IReturnEvidence = {
      evidences: [
        ...response.data.executionsEvidencesExtraExposure,
        ...response.data.executionsEvidences,
      ],
      numberOfStores: response.data.numberOfStores,
    };

    return returnEvidence;
  } catch (error) {
    return null;
  }
}

export function useFetchEvidences(
  filters: IStateFilters,
  industryId?: string
): UseQueryResult<IReturnEvidence | null, unknown> {
  return useQuery(
    ["promoEvidences", filters, industryId],
    () => requestCategory(filters, industryId),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: 2,
    }
  );
}
