import React, { useState } from "react";
import {
  EAdherenceFilter,
  useRequestPriceTable,
} from "../../../services/Hooks/MyExecution/useRequestPriceTable";
import { filter } from "../../../store/SideFilters/RequestFilters/types";
import { IStateFilters } from "../../../store/SideFilters/types";
import TableComplements from "../../TableComplements";

interface IOwnProps {
  SFilters: IStateFilters;
  period: string;
}

const PriceTable: React.FC<IOwnProps> = ({ SFilters, period }) => {
  const [adherenceFilter, setAdherenceFilter] = useState<filter>({
    id: "adherence",
    label: EAdherenceFilter.ADHERENCE,
  });
  const priceTable = useRequestPriceTable(
    period,
    SFilters,
    adherenceFilter.label
  );

  return (
    <TableComplements
      // mainFilter={SAllFilters.data.myExecution.subviewOSA.main.categories}
      // secundaryFilter={SFilters.brand.listFilters || []}
      noFilter
      titleCard="Aderência da estratégia de Preços %"
      subTitleCard="(Mensal)"
      subTitle="Detalhe MARCAS"
      firstColumnMinWidth="400px"
      setAdherence={setAdherenceFilter}
      adherence={adherenceFilter}
      data={priceTable.data}
      isLoading={priceTable.isLoading}
    />
  );
};

export default PriceTable;
