import { Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";

interface IOwnProps {
  children: React.ReactElement;
  title: string;
  subTitle?: string;
  margin?: string;
  selected?: boolean;
  ml?: string;
  mr?: string;
  mt?: string;
  mb?: string;
  bg?: string;
  w?: string;
  minW?: string;
  overflow?: string;
  setSelected?(): void;
}

const Card: React.FC<IOwnProps> = (props: IOwnProps) => {
  return (
    <Stack
      margin={props.margin}
      mt={props.mt}
      ml={props.ml}
      mr={props.mr}
      mb={props.mb}
      w={props.w}
      h="100%"
      overflow={props.overflow || "auto"}
      minW={props.minW || ""}
    >
      <Flex
        h="45px"
        bg="cardHeaderBackgroundColor"
        align="center"
        justify="center"
        color="white"
      >
        <Text fontSize="20px" fontWeight="bold" whiteSpace="nowrap">
          {props.title}{" "}
          <Text fontSize="14px" fontStyle="italic" as="span">
            {props.subTitle}
          </Text>
        </Text>
      </Flex>
      <Flex
        position="relative"
        mt="5px !important"
        bg={props.bg || "white"}
        h="calc(100% - 50px)"
        onClick={props.setSelected}
        _before={
          (props.selected && {
            content: `""`,
            position: "absolute",
            width: "100%",
            height: "100%",
            borderWidth: "4px",
            borderColor: "selectedColor",
            zIndex: "5",
            left: "0",
            top: "0",
          }) ||
          {}
        }
      >
        {props.children}
      </Flex>
    </Stack>
  );
};

Card.defaultProps = {
  margin: "0",
  mb: "0px",
  ml: "0px",
  mr: "0px",
  mt: "0px !important",
  bg: "white",
  w: "100%",
  minW: "",
  overflow: "auto",
  subTitle: "",
  selected: false,
  setSelected: undefined,
};

export default Card;
