import React, { MouseEventHandler, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

// Image icons
import onePageWhiteImg from "../../assets/Menu/dashboard_white.png";
import onePageBlackImg from "../../assets/Menu/dashboard_black.png";
import cartBlackImg from "../../assets/Menu/cart_black.png";
import cartWhiteImg from "../../assets/Menu/cart_white.png";
import storeBlackImg from "../../assets/Menu/store_black.png";
import storeWhiteImg from "../../assets/Menu/store_white.png";
import perfectStoreWhite from "../../assets/Menu/store-white.png";
import perfectStoreBlack from "../../assets/Menu/store.png";
import negotiationsWhiteImg from "../../assets/Menu/negotiations_white.png";
import negotiationsBlackImg from "../../assets/Menu/negotiations_black.png";

import {
  NavItem,
  IconLogin,
  LabelLogin,
} from "../MainContent/MainContent.elements";
import { GlobalProps } from "../../store/index";
import * as ANavigator from "../../store/Navigation/actions";
import {
  stateType,
  INavMainMenu,
  INavContent,
  IMainMenu,
  INavPage,
  ENavSubMenu,
} from "../../store/Navigation/types";
import iconBack from "../../assets/Menu/back_arrow.png";
import ItemMenu from "./ItemMenu";
import { ConfigContext } from "../../services/Context/ConfigContext";

interface viewPages {
  setViewMarketShare: MouseEventHandler;
  setViewMyExecution: MouseEventHandler;
  setViewMyNegociation: MouseEventHandler;
  setViewPromo: MouseEventHandler;
  setViewPerfectStore: MouseEventHandler;
}

interface StateProps {
  SNavigation: stateType;
}

interface IDispathToProps {
  DNavigator: {
    changeMain(indexPage: INavPage): void;
    changeMenu(menuItem: IMainMenu): void;
    changeSubMenu(index: ENavSubMenu): void;
    changeDash(indexContent: INavContent): void;
  };
}

type props = viewPages & StateProps & IDispathToProps;

const Itens: React.FC<props> = (props: props) => {
  const { menuItem, contentDash } = props.SNavigation;

  const { config } = useContext(ConfigContext);

  const isDetailPage = (): boolean => {
    return (
      contentDash === INavContent.MS_BANNER_DATAIL_PRODUCT ||
      contentDash === INavContent.MS_BANNER_DATAIL_STORE ||
      contentDash === INavContent.NPD_DETAIL_PRODUCT ||
      contentDash === INavContent.ME_DETAIL_STORE ||
      contentDash === INavContent.ME_DETAIL_KPI ||
      contentDash === INavContent.ME_DETAIL_KPI_STORE ||
      contentDash === INavContent.MSL_DETAIL_PRODUCT ||
      contentDash === INavContent.MN_DETAIL_COMPLIANCE ||
      contentDash === INavContent.MSL_DETAIL_STORE ||
      contentDash === INavContent.PROMO_PRICE_PRODUCT ||
      contentDash === INavContent.PROMO_PRICE_STORE ||
      contentDash === INavContent.MD_DETAIL_PRODUCT ||
      contentDash === INavContent.MD_DETAIL_STORE
    );
  };

  const actionPage = () => {
    if (
      contentDash === INavContent.MS_BANNER_DATAIL_PRODUCT ||
      contentDash === INavContent.MS_BANNER_DATAIL_STORE
    ) {
      props.DNavigator.changeMenu({
        index: menuItem.index,
        name: "Meu Market Share",
      });
      props.DNavigator.changeDash(INavContent.MAIN_DASH_CONTENT);
      props.DNavigator.changeSubMenu(ENavSubMenu.ONE_PAGE);
    }

    if (
      contentDash === INavContent.NPD_DETAIL_PRODUCT ||
      contentDash === INavContent.ME_DETAIL_STORE ||
      contentDash === INavContent.ME_DETAIL_KPI ||
      contentDash === INavContent.ME_DETAIL_KPI_STORE
    ) {
      props.DNavigator.changeMenu({
        index: menuItem.index,
        name: "Minha Execução",
      });
      props.DNavigator.changeDash(INavContent.MAIN_DASH_CONTENT);
      props.DNavigator.changeSubMenu(ENavSubMenu.ME_NPD);
    }

    if (contentDash === INavContent.MN_DETAIL_COMPLIANCE) {
      props.DNavigator.changeMenu({
        index: menuItem.index,
        name: "Minha Execução",
      });
      props.DNavigator.changeDash(INavContent.MAIN_DASH_CONTENT);
      props.DNavigator.changeSubMenu(ENavSubMenu.ME_NPD);
    }

    if (
      contentDash === INavContent.MD_DETAIL_PRODUCT ||
      contentDash === INavContent.MD_DETAIL_STORE
    ) {
      props.DNavigator.changeMenu({
        index: menuItem.index,
        name: "Meu dia a dia",
      });
      props.DNavigator.changeDash(INavContent.MAIN_DASH_CONTENT);
      props.DNavigator.changeSubMenu(ENavSubMenu.MD_DAY);
    }
  };

  return (
    (isDetailPage() && (
      <NavItem
        img={iconBack}
        imgHover={iconBack}
        onClick={() => actionPage()}
        active
      >
        VOLTAR
      </NavItem>
    )) || (
      <>
        {config.modules.onePage.active && (
          <ItemMenu
            selectedMenu={menuItem.index === INavMainMenu.MY_ONE_PAGE}
            image={
              (menuItem.index === INavMainMenu.MY_ONE_PAGE &&
                onePageBlackImg) ||
              onePageWhiteImg
            }
            labelSup="ONE PAGE"
            labelInf="DASHBOARD"
            setView={props.setViewMarketShare}
          />
        )}
        {config.modules.myExecution.active && (
          <ItemMenu
            selectedMenu={menuItem.index === INavMainMenu.MY_EXECUTION}
            image={
              (menuItem.index === INavMainMenu.MY_EXECUTION && storeBlackImg) ||
              storeWhiteImg
            }
            labelSup="MINHA"
            labelInf="EXECUÇÃO"
            setView={props.setViewMyExecution}
          />
        )}
        {config.modules.myNegotiation.active && (
          <ItemMenu
            selectedMenu={menuItem.index === INavMainMenu.MY_NEGOCIATIONS}
            image={
              (menuItem.index === INavMainMenu.MY_NEGOCIATIONS &&
                negotiationsBlackImg) ||
              negotiationsWhiteImg
            }
            labelSup="MINHAS"
            labelInf="NEGOCIAÇÕES"
            setView={props.setViewMyNegociation}
          />
        )}
        {config.modules.promo.active && (
          <ItemMenu
            selectedMenu={menuItem.index === INavMainMenu.PROMO}
            image={
              (menuItem.index === INavMainMenu.PROMO && cartBlackImg) ||
              cartWhiteImg
            }
            labelSup="MEU"
            labelInf="DIA A DIA"
            setView={props.setViewPromo}
          />
        )}
        <ItemMenu
          selectedMenu={menuItem.index === INavMainMenu.MY_PERFECT_STORE}
          image={
            (menuItem.index === INavMainMenu.MY_PERFECT_STORE &&
              perfectStoreBlack) ||
            perfectStoreWhite
          }
          labelSup="MINHA"
          labelInf="LOJA PERFEITA"
          setView={props.setViewPerfectStore}
        />
      </>
    )
  );
};

interface ownProps {
  name?: string;
  image?: string;
  imgHover?: string;
  action?: () => void;
  loading?: boolean;
}

export const Item: React.FC<ownProps> = (props: ownProps): JSX.Element => {
  return (
    <NavItem
      active
      imgHover={props.imgHover}
      img={props.image}
      onClick={props.action}
    >
      {props.loading && (
        <>
          <IconLogin />
          <LabelLogin />
        </>
      )}
      {props.name}
    </NavItem>
  );
};

Item.defaultProps = {
  name: "",
  imgHover: "",
  image: "",
  action: () => [],
  loading: false,
};

const mapStateToProps = (state: GlobalProps) => ({
  SNavigation: state.navigation,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Itens);
