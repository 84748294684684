import { action } from "typesafe-actions";
import {
  EFilters,
  IChangeDateInitialFilter,
  IChangeDateFinalFilter,
  IChangePromoFilter,
  TPromoFilter,
  IProductFilter,
  IChangeFilter,
  IChangeStoreCNPJ,
  ISelectedInternalFiltersKpi,
  IChangeFilterKpiProduct,
  IChangeFilterKpiStore,
} from "./types";

export const changeFilterIsPromo = (
  dataFilter: TPromoFilter
): IChangePromoFilter => action(EFilters.CHANGE_PROMO_FILTER, dataFilter);

export const changeFilter = (dataFilter: IProductFilter): IChangeFilter =>
  action(EFilters.CHANGE_FILTER, dataFilter);

export const changeDateInitialFilter = (
  payload: string
): IChangeDateInitialFilter =>
  action(EFilters.CHANGE_DATE_INITIAL_FILTER, payload);

export const changeDateFinalFilter = (
  payload: string
): IChangeDateFinalFilter => action(EFilters.CHANGE_DATE_FINAL_FILTER, payload);

export const changeCnpjStore = (cnpj: string): IChangeStoreCNPJ =>
  action(EFilters.CHANGE_STORE_CNPJ_FILTER, cnpj);

export const changeInternalFilterKpiProduct = (
  kpiProduct: ISelectedInternalFiltersKpi
): IChangeFilterKpiProduct =>
  action(EFilters.CHANGE_INTERNAL_FILTER_KPI_PRODUCT, kpiProduct);

export const changeInternalFilterKpiStore = (
  kpiStore: ISelectedInternalFiltersKpi
): IChangeFilterKpiStore =>
  action(EFilters.CHANGE_INTERNAL_FILTER_KPI_STORE, kpiStore);
