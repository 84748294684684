/* eslint-disable camelcase */
export enum typeCategory {
  LOAD_REQUEST = "@filtersCategory/LOAD_REQUEST",
  LOAD_SUCCESS = "@filtersCategory/LOAD_SUCCESS",
  LOAD_FAILURE = "@filtersCategory/LOAD_FAILURE",
}

export interface ILoadRequest {
  type: typeCategory.LOAD_REQUEST;
}

export interface filter {
  id: string;
  label: string;
}

export const defaultValueFilter: filter = {
  id: "",
  label: "",
};

export interface TFilterDetailEnviroment {
  id: string;
  label: string;
  // subChannelId: string;
}

export interface TFilterBanner {
  id: string;
  label: string;
  retailEnviroment: string;
  subChannelId: string;
}

export interface TFilterRegion {
  id: string;
  label: string;
  regionId: string;
}

export interface TFilterCategory {
  id: string;
  label: string;
  businessUnitId: string;
}

export interface TFilterPromoCategory {
  id: string;
  label: string;
  businessPromoUnitId: string;
}

export interface TFilterBrand {
  id: string;
  label: string;
  categoryId: string;
}

export interface TFilterPromoBrand {
  id: string;
  label: string;
  categoryPromoId: string;
}

export interface TFilterSegment {
  id: string;
  label: string;
  brandId: string;
}

export interface TFilterPromoSegment {
  id: string;
  label: string;
  brandPromoId: string;
}

export interface TFilterExecutionType {
  id: string;
  label: string;
  executionTypeId: string;
}

export interface TFilterSalesRep {
  id: string;
  label: string;
  manager: string;
}

export interface TFilterManager {
  id: string;
  label: string;
  director_id: string;
}

export interface TFilterCoordinator {
  id: string;
  label: string;
  manager: string;
}

export interface ILoadSuccess {
  type: typeCategory.LOAD_SUCCESS;
  payload: stateMarketShare;
}

export interface ILoadError {
  type: typeCategory.LOAD_FAILURE;
}

export interface categoryFilter {
  main: {
    subChannel: filter[];
    region: filter[];
  };
  secondary: {
    banner: TFilterBanner[];
    uf: TFilterRegion[];
  };
}

export interface TypeSegments {
  id: string;
  label: string;
  brandId: string;
}

export interface sideBarFilter {
  commercial_structure: {
    director: filter[];
    manager: TFilterManager[];
    // coordinator: TFilterCoordinator[],
    cutomer_sales_rep: TFilterSalesRep[];
  };
  marketShare: {
    subviewCategory: {
      main: {
        subChannel: filter[];
        region: filter[];
        gpdv: filter[];
        retail_enviroment: TFilterDetailEnviroment[];
      };
      secondary: {
        // retail_enviroment: TFilterDetailEnviroment[];
        banner: TFilterBanner[];
        uf: TFilterRegion[];
      };
    };
  };
  myExecution: {
    subviewOSA: {
      main: {
        business_unit: filter[];
        business_unit_promo: filter[];
        itens: string[];
        region: TFilterCategory[];
        subChannel: filter[];
        executions_type: TFilterCategory[];
      };
      secondary: {
        brands: TFilterBrand[];
        brands_promo: TFilterPromoBrand[];
        segments: TypeSegments[];
        segments_promo: TFilterPromoSegment[];
        categories: TFilterCategory[];
        categories_promo: TFilterPromoCategory[];
        execution_leverage: TFilterExecutionType[];
      };
    };
  };
}

export interface stateMarketShare {
  data: sideBarFilter;
  loading: boolean;
  status: number;
  error: boolean;
}
