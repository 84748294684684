import styled, { keyframes } from "styled-components";

import { theme } from "../../../../../styles/theme";

interface Props {
  h?: boolean;
  w?: boolean;
  margin?: string;
  bg?: string;
  showSpinner?: boolean;
  border?: boolean;
  padding?: boolean;
  color?: string;
  filter?: string;
}

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: "#ddcc12";
`;

export const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }
`;

export const UModalCalendar = styled.div<{ width?: string }>`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto auto;
  border-radius: 3px;
  max-width: ${(props) => props.width || "94vw"};
  padding: 8px;
  border-radius: 4px;
  border: 4px solid ${theme.colors.border};
  top: 50%;
  transform: translateY(-50%);
`;

export const ControlHeader = styled.div<Props>`
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  h2 {
    color: ${theme.colors.numberCards};
    font-size: 40px;
  }

  img {
    width: 32px;
    padding: 0 10px;
    margin-top: -2px;
    box-sizing: content-box;
  }

  span {
    flex-direction: column;

    p {
      margin-bottom: 0px;
    }
  }
`;

export const ContainerInput = styled.div<Props>`
  display: flex;
  justify-content: space-between;

  & > form {
    width: 80%;
  }

  p ~ input {
    background-color: red;
  }

  & > div {
    position: relative;
    z-index: 1;
  }

  .error {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    border: 1.5px solid red;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .calendar-div {
    margin-left: 5px;
  }

  div {
    width: 100%;

    label {
      display: block;

      margin-bottom: 0;
      font-weight: bold;
      color: #7a7a7a;
    }

    input {
      width: 100%;
      height: 28px;
      border-radius: 4px;
      padding-left: 5px;

      ${(props) =>
        !props.border &&
        `
          border: 1px solid #7a7a7a;
        `}
    }

    #btn-file {
      background-size: 15px 15px;
      background-position: center;
      width: 20px;
      float: right;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      #file {
        opacity: 0;
      }
    }
  }

  div {
    span {
      p {
        margin-bottom: 0.25rem;
        font-weight: bold;
      }
    }
  }
`;

export const ContainerAlavancas = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
  margin-top: 10px;
  max-height: 550px;
  gap: 5px;
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }

  div {
    margin: 0 auto;
  }
`;

const animateUpload = keyframes`
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }

`;

export const ModalButton = styled.button<Props>`
  background: ${(props) => props.bg} no-repeat center;
  position: relative;
  width: 80px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: ${(props) => props.color || "var(--white)"};
  font-weight: bold;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.margin ? props.margin : "6px 0")};
  filter: ${(props) => props.filter || "none"};
  /* padding: 0.2rem 0.8rem; */

  span {
    content: "";
    display: ${(props) => (props.showSpinner ? "inherit" : "none")};
    width: 18px;
    height: 18px;

    svg {
      animation: ${animateUpload} 1s linear infinite;
    }

    img {
      width: 90%;
    }
  }
`;

export const CardUploadContainer = styled.div<Props>`
  border-right: ${(props) => (props.border ? "1px solid #7A7A7A" : "none")};
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 10px 10px;
  justify-content: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 3px;
  }

  &:nth-child(1) {
    padding-left: 12px;
  }

  p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 0.8rem;
  }

  span {
    color: ${(props) => props.color};
    font-size: 0.9rem;

    img {
      width: 30px;
      margin-top: 8px;
    }
  }
`;

export const ContainerTableModal = styled.div`
  border: 1px solid #e7e6e6;
  padding: 4px;
  margin-bottom: 12px;
  margin-top: 10px;

  overflow-y: auto;
  max-height: 300px;

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px 0 10px;
    max-height: 375px;

    thead {
      th {
        height: 25px;
        padding: 0 5px;
      }

      th:first-child {
        width: 40%;
      }

      th:nth-child(2) {
        text-align: center;
        width: 10%;
      }

      th:nth-child(3) {
        text-align: center;
        width: 10%;
      }

      th:nth-child(4) {
        text-align: center;
        width: 12%;
      }
    }

    tbody {
      td {
        border: 1px solid #e7e6e6;
        font-size: 12px;

        div {
          input {
            width: 100%;
            padding-left: 5px;
          }
        }

        input {
          border: none;

          ::placeholder {
            opacity: 70%;
          }
        }
      }

      td:nth-child(3) {
      }

      td:nth-child(4) {
        width: 100%;
        min-height: 25px;

        display: flex;
        align-items: center;
        justify-content: center;
        label {
          display: flex;
          align-items: center;

          margin-bottom: 0;

          & + label {
            margin-left: 3px;
          }
        }
      }
    }
  }
`;

export const MslCheckbox = styled.div<Props>`
  border-radius: 50%;
  border: 1px solid #e7e6e6;
  background: ${(props) => props.bg};
`;

export const ButtonAlavanca = styled.button<Props>`
  border: none;
  background: transparent;
  margin-bottom: 10px;
  width: 100%;

  border: ${(props) =>
    props.border ? `2px solid ${theme.colors.border}` : "none"};

  img {
    width: 100%;
  }
`;

export const WrapperInputFile = styled.div`
  display: flex;

  & > input {
    margin-right: 5px;
  }
`;
